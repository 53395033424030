import React from 'react';
import { Address } from '../../backend/careo-api';
import { InfoCardRow } from '../ui';

type AddressDetailsProps = {
  address?: Address;
};

export const AddressDetails = ({ address }: AddressDetailsProps) => {
  return (
    <div className="info-card" data-testid="info-card">
      <div className="info-card-title" data-testid="info-card-title">
        Address
      </div>
      <hr />
      <div className="col-md-6">
        <div className="info-card-content row" data-testid="info-card-content">
          <InfoCardRow
            title="Address"
            data={address?.street}
            dataTestId="address"
          />
          <InfoCardRow title="City" data={address?.city} dataTestId="city" />
          <InfoCardRow
            title="County"
            data={address?.county}
            dataTestId="county"
          />
          <InfoCardRow
            title="Region"
            data={address?.region}
            dataTestId="region"
          />
          <InfoCardRow
            title="Country"
            data={address?.country}
            dataTestId="country"
          />
          <InfoCardRow
            title="Post Code"
            data={address?.zip}
            dataTestId="postcode"
          />
        </div>
      </div>
    </div>
  );
};
