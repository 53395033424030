import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AddFinancialProjectDto,
  Project,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, FieldText, Select, SubTitlePage } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  financialProjectSchema,
  handleFormsServerErrors,
} from '../../../utils';
import { useModal } from '../../../contexts/side-modal.context';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';
import { toast } from 'react-toastify';
import {
  projectPaymentFrequencyList,
  projectPaymentTerms,
  projectRevenueTypeList,
} from '../../../constants';

type FinancialFormStepProps = {
  usersList: User[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdProject: Project;
  setCreatedProject: Dispatch<SetStateAction<Project | undefined>>;
  getProjects: () => void;
};

export const FinancialFormStep = ({
  usersList,
  step,
  setStep,
  createdProject,
  setCreatedProject,
  getProjects,
}: FinancialFormStepProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,

    watch,
  } = useForm<AddFinancialProjectDto>({
    resolver: yupResolver(financialProjectSchema),
  });

  const revenue = watch('revenue');
  const cost = watch('cost');

  const marginPercentage = useMemo(() => {
    if (revenue && cost) {
      const marginValue = ((revenue - cost) / revenue) * 100;
      return marginValue.toFixed(2);
    }
    return null;
  }, [cost, revenue]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmitFinancialProject = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto: AddFinancialProjectDto = {
      ...values,
      revenue: Number(values.revenue),
      margin: Number(values.margin),
      cost: Number(values.cost),
      purchaseOrderNumber: Number(values.purchaseOrderNumber),
    };

    await AxiosInstance.projects
      .projectsControllerAddFinancialProjectId(createdProject._id, requestDto)
      .then((response) => {
        setCreatedProject(response.data);
        getProjects();
        if (isWithExist) {
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('Financial Project saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdProject) {
      reset({
        ...createdProject,
        contactId: createdProject.contact?._id,
      });
    }
  }, [createdProject]);

  return (
    <>
      <SubTitlePage data-testid="financials-subtitle">Financials</SubTitlePage>
      <div className="row">
        <div className="col-md-3">
          <Select
            placeholder="Select Revenue per"
            label="Revenue per"
            options={projectRevenueTypeList.map((el) => ({
              label: el,
              value: el,
            }))}
            data-testid="select-revenue-per"
            required
            register={register('revenuePer')}
            error={errors.revenuePer}
            control={control}
          />
        </div>
        <div className="col-md-3">
          <FieldText
            placeholder="Enter here ..."
            label="&nbsp;"
            data-testid="field-revenue"
            type="currency"
            register={register('revenue')}
            error={errors.revenue}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Cost"
            data-testid="field-cost"
            type="currency"
            required
            register={register('cost')}
            error={errors.cost}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Margin"
            data-testid="field-margin"
            type="currency"
            required
            register={register('margin')}
            error={errors.margin}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Purchase order number"
            data-testid="field-purchase-order"
            required
            register={register('purchaseOrderNumber')}
            error={errors.purchaseOrderNumber}
            type="currency"
          />
        </div>
      </div>

      <SubTitlePage data-testid="financials-subtitle-end">
        Financials
      </SubTitlePage>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Select Contact"
            label="Contact"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            data-testid="select-contact"
            register={register('contactId')}
            required
            error={errors.contactId}
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Invoicing To"
            data-testid="field-invoicing-to"
            required
            register={register('invoicingTo')}
            error={errors.invoicingTo}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Invoicing CC"
            data-testid="field-invoicing-cc"
            required
            register={register('invoicingCC')}
            error={errors.invoicingCC}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Staff Pay Frequency"
            data-testid="field-staff-pay-frequency"
            options={projectPaymentFrequencyList.map((el) => ({
              label: el,
              value: el,
            }))}
            required
            register={register('staffPayFrequency')}
            error={errors.staffPayFrequency}
            control={control}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Payment Terms"
            data-testid="field-payment-terms"
            options={projectPaymentTerms.map((el) => ({
              label: el,
              value: el,
            }))}
            required
            register={register('paymentTerms')}
            error={errors.paymentTerms}
            control={control}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Invoice Frequency"
            data-testid="field-invoice-frequency"
            options={projectPaymentFrequencyList.map((el) => ({
              label: el,
              value: el,
            }))}
            required
            register={register('invoiceFrequency')}
            error={errors.invoiceFrequency}
            control={control}
          />
        </div>
      </div>

      <div className="form-actions stepper" data-testid="form-actions">
        <div className="left-container">
          <Button
            onClick={handleSubmit(() => onSubmitFinancialProject(true))}
            type="primary"
            variant="outlined"
            data-testid="save-exit-button"
          >
            Save & Exit
          </Button>
          <Button
            type="danger"
            variant="outlined"
            onClick={() => closeModal()}
            data-testid="cancel-button"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button onClick={() => setStep(2)}>
            <LeftArrowIcon /> Back
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitFinancialProject(false))}
            data-testid="next-button" // Added test ID for Next button
            disabled={isSubmitting}
          >
            Next <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};
