import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file-list.component';
import { DocumentsListComponent } from '../../document/documents-list.component';
import { useModal } from '../../../contexts/side-modal.context';

type VerifiedIdentificationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'ids' | 'driverLicense' | 'proofAddress';

export const VerifiedIdentification = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: VerifiedIdentificationProps) => {
  const { id } = useParams();

  const [isValid, setIsValid] = useState<boolean>();

  const { openModal, closeModal } = useModal();

  const onClickEdit = (fileKey: TFilesKey) => {
    openModal({
      title: 'Select File',
      component: (
        <SelectFileList
          fileKey={fileKey}
          onCancel={() => closeModal()}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list"
        />
      ),
    });
  };

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([
    { key: 'ids', files: [] },
    { key: 'driverLicense', files: [] },
    { key: 'proofAddress', files: [] },
  ]);
  const { register, getValues, setValue } = useForm<{
    ids: any[];
    driverLicense: any[];
    proofAddress: any[];
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'ids' | 'driverLicense' | 'proofAddress',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const ids = compliance?.metadata?.ids ?? [];
      const driverLicense = compliance?.metadata?.driverLicense ?? [];
      const proofAddress = compliance?.metadata?.proofAddress ?? [];

      setValue('ids', ids);
      setValue('driverLicense', driverLicense);
      setValue('proofAddress', proofAddress);
      setFilesList([
        { key: 'ids', files: ids },
        { key: 'driverLicense', files: driverLicense },
        { key: 'proofAddress', files: proofAddress },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      <TitleCard data-testid="title-card">Qualifying Documents</TitleCard>
      <div
        className="description"
        data-testid="qualifying-documents-description"
      >
        Qualifying documents = 2 photo ID's. 1 proof of address **Refer to
        appendix 1 via the following web link**
        https://www.nhsemployers.org/publications/identity-checks-standard
      </div>

      {/* ID Photo Section */}
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-ids"
      >
        <div className="col-md-6" data-testid="col-ids">
          <FieldFile
            label="ID Photo"
            onChange={(e) => handleFileChange(e, 'ids')}
            data-testid="field-file-ids"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit('ids')}
          data-testid="select-ids-button"
        >
          <Button type="primary" data-testid="select-ids-btn">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={filesList.find((el) => el.key === 'ids')?.files ?? []}
        onClickDelete={(id) => {
          onClickDelete('ids', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid="documents-list-ids"
      />

      {/* Driver License Section */}
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-driverLicense"
      >
        <div className="col-md-6" data-testid="col-driverLicense">
          <FieldFile
            label="Driver License"
            onChange={(e) => handleFileChange(e, 'driverLicense')}
            data-testid="field-file-driverLicense"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit('driverLicense')}
          data-testid="select-driverLicense-button"
        >
          <Button type="primary" data-testid="select-driverLicense-btn">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'driverLicense')?.files ?? []
        }
        onClickDelete={(id) => {
          onClickDelete('driverLicense', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid="documents-list-driverLicense"
      />

      {/* Proof of Address Section */}
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-proofAddress"
      >
        <div className="col-md-6" data-testid="col-proofAddress">
          <FieldFile
            label="Proof of Address"
            onChange={(e) => handleFileChange(e, 'proofAddress')}
            data-testid="field-file-proofAddress"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit('proofAddress')}
          data-testid="select-proofAddress-button"
        >
          <Button type="primary" data-testid="select-proofAddress-btn">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'proofAddress')?.files ?? []
        }
        onClickDelete={(id) => {
          onClickDelete('proofAddress', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid="documents-list-proofAddress"
      />

      <TitleCard data-testid="title-card-admin-checks">
        Administer validity checks for YOTI or Trust ID
      </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="yoti"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        data-testid="yoti-radio-group"
      />
    </>
  );
};
