import React, { Dispatch, SetStateAction, useState } from 'react';
import { SideModalContainer } from './side-modal.style';
import { DotsIcon, RightArrowIcon } from '../../../../icons';
import { Button, TitlePage } from '../../../ui';
import { Menu, MenuItem } from '@mui/material';

type SideModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  children: any;
  onClose?: () => void;
  actions?: { label: string; action: () => void; 'data-testid'?: string }[];
};

export enum EModalTypes {
  Create = 'Create',
  Update = 'Update',
  NewAvailability = 'NewAvailability',
  AddToVacancy = 'AddToVacancy',
}

export const SideModal = ({
  isOpen,
  setIsOpen,
  title,
  children,
  onClose = () => {},
  actions = [],
}: SideModalProps) => {
  const _onClose = () => {
    setIsOpen(false);
    onClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <SideModalContainer data-testid="side-modal-container">
      <Button
        className="back-button"
        onClick={_onClose}
        data-testid="back-button"
      >
        <RightArrowIcon />
      </Button>
      <div className="content-container">
        {title && (
          <div className="side-modal-header-container">
            <TitlePage className="side-modal-title">{title}</TitlePage>
            {!!actions?.length && (
              <>
                <div
                  className="action-container"
                  onClick={(e) => handleClick(e)}
                >
                  <DotsIcon />
                </div>
                <Menu
                  id="sidebar-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {actions.map((el) => (
                    <MenuItem
                      onClick={() => {
                        el.action();
                        handleClose();
                      }}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </div>
        )}

        {children}
      </div>
    </SideModalContainer>
  );
};
