import React, { useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, Textarea, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Candidate, CreateCampaignTemplateDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  campaignTemplateSchema,
  generateMessageFromTemplate,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type NewCampaignTemplateFormProps = {
  onCancel: () => void;
  getCampaignTemplates: () => void;
};

export const NewCampaignTemplateForm = ({
  onCancel,
  getCampaignTemplates,
}: NewCampaignTemplateFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreateCampaignTemplateDto>({
    resolver: yupResolver(campaignTemplateSchema as any),
  });
  const formValues = watch();

  const [result, setResult] = useState('');

  const onClickClose = () => {
    onCancel();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.campaignTemplates
      .campaignTemplatesControllerCreate({
        ...values,
      })
      .then(() => {
        toast.success('Campaign Template added successfully');
        getCampaignTemplates();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setResult(() => {
      return generateMessageFromTemplate(formValues.message, {
        title: 'Mr.',
        firstName: 'John',
        lastName: 'Doe',
      } as Candidate);
    });
  }, [formValues]);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Template name"
        required
        register={register('name', { required: true })}
        error={errors.name}
        data-testid="template-name-field"
      />
      <TitleCard data-testid="message-options-title">Message Options</TitleCard>
      <ul data-testid="message-options-list">
        <li>
          <b>[[title]]</b> : title of candidate (Mrs., Mr., Ms., Dr., Prof.)
        </li>
        <li>
          <b>[[firstName]]</b> : first name of candidate
        </li>
        <li>
          <b>[[lastName]]</b> : last name of candidate
        </li>
      </ul>
      <Textarea
        label="Template Message"
        required
        register={register('message', { required: true })}
        placeholder={`Dear [title] [lastName],
  I hope you are doing well!`}
        rows={3}
        error={errors.message}
        data-testid="template-message-textarea"
      />
      <Textarea
        label="Result (Example)"
        rows={3}
        value={result}
        disabled
        data-testid="result-textarea"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
