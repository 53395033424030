import React, { useEffect, useState } from 'react';
import { SettingsContainer } from './settings.style';
import {
  CampaignTemplates,
  CardContainer,
  PayrollSetting,
  SubTitlePage,
  TimesheetSetting,
  TitlePage,
} from '../../components';
import { InfoIcon } from '../../icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CampaignsList, CompanyDetails, UsersList } from '../../components';
import { isCRMApp } from '../../environment/app.type';

enum ESettings {
  TEAM_ACCESS = 'Team Access',
  CAMPAIGNS = 'Campaigns',
  CAMPAIGN_TEMPLATE = 'Campaign Templates',
  COMPANY = 'Company',
  PAYROLL_SELECTION = 'Payroll Selection',
  TIMESHEET = 'Timesheet',
}

const tabs = [
  ESettings.TEAM_ACCESS,
  ESettings.CAMPAIGNS,
  ESettings.CAMPAIGN_TEMPLATE,
  ESettings.COMPANY,
  ESettings.PAYROLL_SELECTION,
  ESettings.TIMESHEET,
];

export const SettingsPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState<ESettings>(
    ESettings.TEAM_ACCESS,
  );

  useEffect(() => {
    if (!type) {
      setSelectedTab(ESettings.TEAM_ACCESS);
      return;
    }

    setSelectedTab(tabs.find((el) => el === type) ?? ESettings.TEAM_ACCESS);
  }, [location]);

  return (
    <>
      <SettingsContainer data-testid="settings-container">
        <div className="header-page" data-testid="settings-header">
          <TitlePage data-testid="settings-title">Settings</TitlePage>
          <SubTitlePage data-testid="settings-subtitle">
            Manage your Settings
          </SubTitlePage>
        </div>

        <div className="content-page" data-testid="settings-content">
          {isCRMApp && (
            <CardContainer
              className="left-container"
              data-testid="settings-left-container"
            >
              <div
                className="setting-list-items"
                data-testid="settings-list-items"
              >
                {tabs.map((el) => (
                  <div
                    className={`setting-item ${selectedTab === el && 'active'}`}
                    onClick={() => navigate('/settings/' + el)}
                    data-testid={`settings-item-${el.toLowerCase()}`}
                  >
                    <InfoIcon />
                    {el}
                  </div>
                ))}
              </div>
            </CardContainer>
          )}
          {selectedTab === ESettings.TEAM_ACCESS && (
            <UsersList data-testid="team-access" />
          )}
          {selectedTab === ESettings.CAMPAIGNS && (
            <CampaignsList data-testid="campaigns-list" />
          )}
          {selectedTab === ESettings.CAMPAIGN_TEMPLATE && (
            <CampaignTemplates data-testid="campaign-templates" />
          )}
          {selectedTab === ESettings.PAYROLL_SELECTION && (
            <PayrollSetting data-testid="payroll-setting" />
          )}
          {selectedTab === ESettings.TIMESHEET && (
            <TimesheetSetting data-testid="timesheet-setting" />
          )}
          {selectedTab === ESettings.COMPANY && (
            <CompanyDetails data-testid="company-details" />
          )}
        </div>
      </SettingsContainer>
    </>
  );
};
