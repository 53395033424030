import React from 'react';
import {
  ComplianceCandidatesList,
  SubTitlePage,
  TitlePage,
  ComplianceStatistics,
} from '../../components';
import { useAuth } from '../../contexts/auth.context';

const HomeCompliancePage = () => {
  const user = useAuth();

  return (
    <>
      <div
        className="welcome-message"
        style={{ gap: 0 }}
        data-testid="welcome-message"
      >
        <TitlePage data-testid="title-page">
          Hi, {user.user?.firstName}
        </TitlePage>
        <SubTitlePage data-testid="subtitle-page">
          This is your HR report so far
        </SubTitlePage>
      </div>

      <ComplianceStatistics data-testid="compliance-statistics" />

      <ComplianceCandidatesList data-testid="compliance-candidates-list" />
    </>
  );
};

export default HomeCompliancePage;
