import styled, { css } from 'styled-components';
import { breakpoint } from '../../../constants';

type DragDropUploadContainerProps = {
  isDragging: boolean;
};

export const DragDropUploadContainer = styled.div<DragDropUploadContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  transition:
    border-color 0.3s,
    background-color 0.3s;

  ${(props) =>
    props.isDragging &&
    css`
      border-color: #2196f3;
      background-color: #f1faff;
    `}

  .drag-drop-description {
    text-align: center;
    .drag-drop-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .drag-drop-subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 16px;
    gap: 16px;
    .drag-drop-description {
      .drag-drop-title {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .drag-drop-subtitle {
        font-size: 10px;
      }
    }
  }
`;
