import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Define keyframes for the loader rotation animation
const rotateArc = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ChartContainer = styled.div<{ size?: number; loading?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible; /* Ensure overflowing elements are visible */
  z-index: 10; /* Ensure the chart is not clipped by other elements */

  .chart-details {
    box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
    width: ${({ size = 200 }) => size * 0.5}px;
    height: ${({ size = 200 }) => size * 0.5}px;
    border-radius: ${({ size = 200 }) => size * 0.25}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    z-index: 1;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* Loader Arc */
    ${({ loading }) =>
      loading &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid transparent;
          border-top-color: #27a376; /* The color of the rotating arc */
          animation: ${rotateArc} 1s linear infinite;
        }
      `}

    .chart-title {
      font-size: 20px;
    }
    .chart-subtitle {
      font-size: 15px;
      color: #808080;
    }
  }

  .chart-container {
    width: ${({ size = 300 }) => size}px;
    z-index: 1;
    position: relative; /* Relative position for stacking context */
  }
`;

export type DonutChartConfig = {
  labels: string[];
  backgroundColors?: string[];
  borderColors?: string[];
  title?: string;
  subtitle?: string;
  size?: number;
};

type DonutChartProps = {
  data: number[];
  config: DonutChartConfig;
  onArcClick?: (label: string, value: number) => void;
  selectedItem?: string;
  isLoading?: boolean;
};

export const DonutChart: React.FC<DonutChartProps> = ({
  data,
  config: {
    labels,
    backgroundColors = ['#ee2f2f', '#ffd023', '#27a376'],
    borderColors = ['#ee2f2f', '#ffd023', '#27a376'],
    title = 'Chart',
    subtitle = 'Details',
    size = 200,
  },
  onArcClick,
  isLoading = false,
  selectedItem = '',
}) => {
  const totalTasks = data.reduce((acc, val) => acc + val, 0);
  const offsets = labels.map((label) => (label === selectedItem ? 20 : 0));

  return (
    <ChartContainer
      size={size}
      loading={isLoading}
      data-testid="chart-container"
    >
      <div className="chart-container" data-testid="chart-visual">
        <Doughnut
          data={{
            labels: totalTasks ? labels : [''],
            datasets: [
              {
                data: totalTasks ? data : [1],
                backgroundColor: totalTasks ? backgroundColors : ['#e3e3e3'],
                borderColor: totalTasks ? borderColors : ['#e3e3e3'],
                borderWidth: 1,
                borderRadius: 4,
                spacing: 4,
                hoverOffset: 20,
                offset: offsets,
              },
            ],
          }}
          options={{
            cutout: '70%',
            plugins: {
              legend: {
                display: false,
              },
            },
            onClick: (event: any, elements: any[]) => {
              if (elements.length > 0 && onArcClick) {
                const index = elements[0].index;
                const label = labels[index];
                const value = data[index];
                onArcClick(label, value);
              }
            },
            animation: {
              animateRotate: true,
              animateScale: true,
              duration: 300, // Duration of the animation
            },
            layout: {
              padding: 10,
            },
            onHover(event, elements, chart) {
              (event?.native?.target as HTMLElement).style.cursor =
                elements?.length > 0 ? 'pointer' : 'auto';
            },
          }}
          data-testid="doughnut-chart" // Added data-testid here
        />
      </div>
      <div className="chart-details" data-testid="chart-details">
        <div className="chart-title" data-testid="chart-title">
          {!isLoading ? totalTasks : <>...</>}
        </div>
        <div className="chart-subtitle" data-testid="chart-subtitle">
          {subtitle}
        </div>
      </div>
    </ChartContainer>
  );
};

export const DonutChartPercentage: React.FC<DonutChartProps> = ({
  data,
  config: {
    labels,
    backgroundColors = ['#11af60'],
    borderColors = ['#11af60'],
    size = 200,
  },
  onArcClick,
  isLoading = false,
  selectedItem = '',
}) => {
  const totalPercentage = data.reduce((acc, val) => acc + val, 0);
  const remainPercentage = 100 - totalPercentage;
  const offsets = labels.map((label) => (label === selectedItem ? 20 : 0));

  return (
    <ChartContainer size={size} loading={isLoading}>
      <div className="chart-container" data-testid="chart-container">
        <Doughnut
          data={{
            labels: [...labels, 'Empty'],
            datasets: [
              {
                data: [...data, remainPercentage],
                backgroundColor: [...backgroundColors, '#e3e3e3'],
                borderColor: [...borderColors, '#e3e3e3'],
                borderWidth: 1,
                borderRadius: 4,
                spacing: 4,
                hoverOffset: 20,
                offset: offsets,
              },
            ],
          }}
          options={{
            cutout: '70%',
            plugins: {
              legend: {
                display: false,
              },
            },
            onClick: (event: any, elements: any[]) => {
              if (elements.length > 0 && onArcClick) {
                const index = elements[0].index;
                const label = labels[index];
                const value = data[index];
                onArcClick(label, value);
              }
            },
            animation: {
              animateRotate: true,
              animateScale: true,
              duration: 300, // Duration of the animation
            },
            layout: {
              padding: 10,
            },
            onHover(event, elements, chart) {
              (event?.native?.target as HTMLElement).style.cursor =
                elements?.length > 0 ? 'pointer' : 'auto';
            },
          }}
        />
      </div>
      <div className="chart-details" data-testid="chart-details">
        <div className="chart-title" data-testid="chart-title">
          {!isLoading ? <>{totalPercentage}%</> : <>...</>}
        </div>
      </div>
    </ChartContainer>
  );
};
