import React, { useState } from 'react';
import styled from 'styled-components';

const AccordionContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  &.primary-item {
    border-radius: 16px;
    border: 1px solid #f1f2f4;
    background: #fff;
    .accordion-button {
      border: unset !important;
      padding: 24px;
      border-bottom: 1px solid #f1f2f4 !important;
    }

    > h2 > .accordion-button {
      font-size: 20px;
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &.secondary-item {
    .accordion-button {
      border: unset !important;
      padding: 2px 0px;
    }
    .accordion-body {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .accordion-item {
    .accordion-collapse {
      margin: 24px;
    }
    .accordion-body {
      padding: unset;
    }

    .secondary-item .accordion-collapse {
      margin: 24px 0;
    }

    .accordion-collapse {
      border: unset;
    }

    .delete-icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e03137;
      gap: 10px;
      border-radius: 10px;
      svg {
        fill: white;
      }
    }
  }

  &.info-card {
    border: unset;
    padding: unset;
  }
`;

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  isOpenByDefault?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  className = '',
  isOpenByDefault = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleAccordion = async () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <AccordionContainer className={className} data-testid="accordion">
      <div className="accordion-item" data-testid="accordion-item">
        <h2 className="accordion-header" data-testid="accordion-header">
          <button
            className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
            type="button"
            onClick={toggleAccordion}
            aria-expanded={isOpen}
            data-testid="accordion-button"
          >
            <b>{title}</b>
          </button>
        </h2>
        <div
          className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="accordion-header"
          data-testid="accordion-content"
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </AccordionContainer>
  );
};
