import React, { useEffect, useState } from 'react';
import { ComplianceEditContainer } from './compliance-edit.style';
import { ATag, TabPageContainer } from '../../ui';
import { EmploymentHistory } from './employment-history.component';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
} from '../../../utils';
import {
  Compliance,
  EComplianceName,
  HostedFile,
} from '../../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import { RightWork } from './right-work.component';
import { toast } from 'react-toastify';
import { VerifiedIdentification } from './verified-identication.component';
import { DbsCheck } from './dbs-check.component';
import { Qualifications } from './qualifications.component';
import { ProfessionalRegistration } from './professional-registration.component';
import { OccupationHealth } from './occupation-health.component';

export const complianceTabs = [
  EComplianceName.EmploymentHistory,
  EComplianceName.RightToWork,
  EComplianceName.VerifiedIdentification,
  EComplianceName.DBSCheck,
  EComplianceName.Qualifications,
  EComplianceName.ProfessionalRegistration,
  EComplianceName.OccupationalHealth,
];

type ComplianceEditProps = {
  selectedTab: Compliance | null;
  getComplianceList: () => void;
};

export const ComplianceEdit = ({
  selectedTab,
  getComplianceList,
}: ComplianceEditProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [compliance, setCompliance] = useState<Compliance>();
  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setDocuments(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickSubmit = async (
    id: string,
    values: any,
    aiCheck = false,
    successMessage = 'Compliance updated successfully',
  ) => {
    successMessage =
      aiCheck === true ? 'Validation is in process ...' : successMessage;
    await AxiosInstance.compliances
      .compliancesControllerUpdate(
        id,
        { validate: !!aiCheck },
        {
          metadata: {
            ...values,
          },
        },
      )
      .then(() => {
        getCandidateDocuments();
        getComplianceList();
        reFetchCompliance(id);
        toast.success(successMessage);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const reFetchCompliance = (id: string) => {
    AxiosInstance.compliances
      .compliancesControllerGetCompliance(id)
      .then((response) => {
        setCompliance(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onUpdateValidation = (idCompliance: string, value: string) => {
    const isValid = value === 'true' ? true : false;
    AxiosInstance.compliances
      .compliancesControllerUpdate(
        idCompliance,
        { validate: false },
        {
          isValid,
        },
      )
      .then(() => {
        getComplianceList();
        toast.success('Compliance updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (selectedTab) setCompliance(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    getCandidateDocuments();
  }, []);

  return (
    <ComplianceEditContainer data-testid="compliance-edit-container">
      <TabPageContainer data-testid="tab-page-container">
        <div className="tab-items-list" data-testid="tab-items-list">
          {complianceTabs.map((el) => (
            <ATag
              className={`tab-item ${el === compliance?.taskName && 'active'}`}
              onClick={() =>
                navigate(`/${ERoute.CANDIDATES}/${id}/compliance/${el}`)
              }
              key={el}
              data-testid={`tab-item-${el}`} // Added a unique test ID for each tab
            >
              {el}
            </ATag>
          ))}
        </div>
        <div
          className="tab-content-container"
          data-testid="tab-content-container"
        >
          {compliance?.taskName === EComplianceName.EmploymentHistory && (
            <EmploymentHistory
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => {
                reFetchCompliance(compliance._id);
              }}
              onUpdateValidation={onUpdateValidation}
              data-testid="employment-history" // Added test ID for EmploymentHistory
            />
          )}
          {compliance?.taskName === EComplianceName.RightToWork && (
            <RightWork
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="right-to-work" // Added test ID for RightWork
            />
          )}
          {compliance?.taskName === EComplianceName.VerifiedIdentification && (
            <VerifiedIdentification
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="verified-identification" // Added test ID for VerifiedIdentification
            />
          )}
          {compliance?.taskName === EComplianceName.DBSCheck && (
            <DbsCheck
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="dbs-check" // Added test ID for DbsCheck
            />
          )}
          {compliance?.taskName === EComplianceName.Qualifications && (
            <Qualifications
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="qualifications" // Added test ID for Qualifications
            />
          )}
          {compliance?.taskName ===
            EComplianceName.ProfessionalRegistration && (
            <ProfessionalRegistration
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="professional-registration" // Added test ID for ProfessionalRegistration
            />
          )}
          {compliance?.taskName === EComplianceName.OccupationalHealth && (
            <OccupationHealth
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="occupational-health" // Added test ID for OccupationalHealth
            />
          )}
        </div>
      </TabPageContainer>
    </ComplianceEditContainer>
  );
};
