import React, { useEffect, useMemo, useState } from 'react';
import { Candidate } from '../../backend/careo-api';
import { SortIcon } from '../../icons';
import {
  TFilterCandidate,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../utils';
import {
  SearchInput,
  Table,
  Checkbox,
  UserIcon,
  Badge,
  Pagination,
} from '../ui';

const itemsPerPage = 5;

type FilterCandidatesProps = {
  candidates: Candidate[];
  selectedCandidatesIds: string[];
  setSelectedCandidatesIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const FilterCandidates = ({
  candidates,
  selectedCandidatesIds,
  setSelectedCandidatesIds,
}: FilterCandidatesProps) => {
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    grades: [],
    levels: [],
    status: [],
    completions: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        return [...candidatesList.map((el) => el._id)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, itemsPerPage);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(candidates, filter);
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidates, filter, sort]);

  return (
    <>
      <div className="filter-container" data-testid="filter-container">
        <SearchInput
          placeholder="Search candidate"
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
          }
          data-testid="search-input"
        />
      </div>
      <div className="data-table-container" data-testid="data-table-container">
        <Table data-testid="candidates-table">
          <thead>
            <tr>
              <th className="checkbox-table" data-testid="checkbox-header">
                <Checkbox
                  checked={
                    !!selectedCandidatesIds.length &&
                    selectedCandidatesIds.length === candidatesList.length
                  }
                  indeterminate={
                    !!selectedCandidatesIds.length &&
                    selectedCandidatesIds.length < candidatesList.length
                  }
                  onChange={(e) => onClickCheckboxAll(e.target.checked)}
                  data-testid="select-all-checkbox"
                />
              </th>
              <th
                onClick={() => onSelectSort('firstName', setSort)}
                data-testid="sort-firstname"
              >
                <div>
                  <label>Name</label>
                  <SortIcon
                    value={sort.key === 'firstName' ? sort.value : ''}
                    data-testid="sort-firstname-icon"
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('designation', setSort)}
                data-testid="sort-designation"
              >
                <div>
                  <label>Job title</label>
                  <SortIcon
                    value={sort.key === 'designation' ? sort.value : ''}
                    data-testid="sort-designation-icon"
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('grade', setSort)}
                data-testid="sort-grade"
              >
                <div>
                  <label>NHS Pay Grade</label>
                  <SortIcon
                    value={sort.key === 'grade' ? sort.value : ''}
                    data-testid="sort-grade-icon"
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('county', setSort)}
                data-testid="sort-county"
              >
                <div>
                  <label>County</label>
                  <SortIcon
                    value={sort.key === 'county' ? sort.value : ''}
                    data-testid="sort-county-icon"
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('region', setSort)}
                data-testid="sort-region"
              >
                <div>
                  <label>Region</label>
                  <SortIcon
                    value={sort.key === 'region' ? sort.value : ''}
                    data-testid="sort-region-icon"
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('status', setSort)}
                data-testid="sort-status"
              >
                <div>
                  <label>Status</label>
                  <SortIcon
                    value={sort.key === 'status' ? sort.value : ''}
                    data-testid="sort-status-icon"
                  />
                </div>
              </th>
              <th data-testid="actions-header">
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((candidate) => (
              <tr
                key={candidate._id}
                data-testid={`candidate-row-${candidate._id}`}
              >
                <td
                  className="checkbox-table"
                  data-testid={`checkbox-cell-${candidate._id}`}
                >
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      onClickCheckbox(candidate._id, e.target.checked)
                    }
                    checked={selectedCandidatesIds.some(
                      (el) => el === candidate._id,
                    )}
                    data-testid={`checkbox-${candidate._id}`}
                  />
                </td>
                <td data-testid={`candidate-name-${candidate._id}`}>
                  <div className="name-item">
                    <UserIcon
                      firstName={candidate.firstName}
                      lastName={candidate.lastName}
                      entity="candidate"
                      data-testid={`user-icon-${candidate._id}`}
                    />
                    <div>
                      <div>
                        {candidate.firstName} {candidate.lastName}
                      </div>
                      <div
                        className="email"
                        data-testid={`candidate-email-${candidate._id}`}
                      >
                        {candidate.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td data-testid={`candidate-job-title-${candidate._id}`}>
                  <div className="name-item">
                    <div>
                      <div>{candidate?.designation || '-'}</div>
                      <div className="email">{candidate.department || '-'}</div>
                    </div>
                  </div>
                </td>
                <td data-testid={`candidate-grade-${candidate._id}`}>
                  {candidate?.grade}
                </td>
                <td data-testid={`candidate-county-${candidate._id}`}>
                  {candidate.address?.county || '-'}
                </td>
                <td data-testid={`candidate-region-${candidate._id}`}>
                  {candidate.address?.region || '-'}
                </td>
                <td>
                  <Badge
                    type={'success'}
                    data-testid={`candidate-status-${candidate._id}`}
                  >
                    Active
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        totalEntries={candidatesList.length}
        data-testid="pagination"
      />
    </>
  );
};
