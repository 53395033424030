import styled from 'styled-components';

export const LeadContainerPage = styled.div`
  .target-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left-container {
    }
    .right-container {
      display: flex;
      align-items: center;
      gap: 40px;
      flex: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
      .budget-container {
        font-size: 12px;
        font-weight: 700;
        .title {
        }
        .numbers {
          .green {
            color: #0caf60;
          }
        }
        .edit-icon {
          background-color: #2f78ee;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-radius: 10px;
          cursor: pointer;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  .leads-list-container {
    .header-leads-list-container {
      .right-container {
        display: flex;
        align-items: center;
      }
    }
  }
`;
