import React from 'react';
import { Badge, Switch, Table } from '../ui';
import { DeleteIcon, DownloadIcon, EditIcon, EyeIcon } from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatDate,
} from '../../utils';
import {
  Compliance,
  EComplianceName,
  EComplianceStatus,
} from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { TUIType } from '../../constants';
import { isCRMApp } from '../../environment/app.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceStatus } from './compliance-status.component';
import { downloadFile } from '../../utils';
import { ComplianceDateForm } from './compliance-date-form.component';
import { useModal } from '../../contexts/side-modal.context';

const ComplianceStatusBadge = ({
  status,
}: {
  status?: EComplianceStatus | 'Not active';
}) => {
  let value: { text: string; type: TUIType } = {
    text: 'Not Active',
    type: 'warning',
  };

  switch (status) {
    case EComplianceStatus.IN_PROGRESS:
      value = { text: 'Pending', type: 'info' };
      break;
    case EComplianceStatus.PASSED:
      value = { text: 'ACTIVE', type: 'success' };
      break;
    case EComplianceStatus.FAILED:
      value = { text: 'Rejected', type: 'primary' };
      break;
    case EComplianceStatus.EXPIRED:
      value = { text: 'Expired', type: 'danger' };
      break;
    default:
      value = { text: 'Not Active', type: 'warning' };
      break;
  }

  return <Badge type={value.type}> {value.text}</Badge>;
};

type ComplianceListProps = {
  compliancesList: Compliance[];
  getComplianceList?: () => Promise<void>;
};

export const ComplianceList = ({
  compliancesList,
  getComplianceList,
}: ComplianceListProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { openModal, closeModal } = useModal();

  const onClickEdit = (compliance: Compliance) => {
    openModal({
      title: 'Update Date',
      component: (
        <ComplianceDateForm
          compliance={compliance}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getComplianceList?.();
            closeModal();
          }}
          data-testid="compliance-date-form"
        />
      ),
    });
  };

  const onUpdateRequired = async (
    compliance: Compliance,
    newValue: boolean,
  ) => {
    if (!compliance.reminderDate || !compliance.expiryDate) {
      toast.warning('Please set the reminder date and the expiry date');
      return;
    }

    await AxiosInstance.compliances
      .compliancesControllerUpdate(
        compliance._id,
        { validate: false },
        {
          isRequired: newValue,
        },
      )
      .then(() => {
        getComplianceList?.();
        toast.success('Compliance updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const downloadAllComplianceFile = (compliance: Compliance) => {
    if (compliance.taskName === EComplianceName.DBSCheck) {
      if (compliance.metadata?.dbsCertificateFile) {
        downloadFile(compliance.metadata?.dbsCertificateFile);
      } else {
        toast.error('No file exists');
      }
    } else if (compliance.taskName === EComplianceName.EmploymentHistory) {
      if (
        compliance.metadata?.cvFile ||
        compliance.metadata?.references?.length
      ) {
        if (compliance.metadata?.cvFile) {
          downloadFile(compliance.metadata?.cvFile);
        }
        compliance.metadata?.references?.map((el) => downloadFile(el));
      } else {
        toast.error('No file exists');
      }
    } else if (compliance.taskName === EComplianceName.OccupationalHealth) {
      if (
        compliance.metadata?.questionnaireFiles?.length ||
        compliance.metadata?.questionnaireFiles2?.length
      ) {
        compliance.metadata?.questionnaireFiles?.map((el) => downloadFile(el));
        compliance.metadata?.questionnaireFiles2?.map((el) => downloadFile(el));
      } else {
        toast.error('No file exists');
      }
    } else if (
      compliance.taskName === EComplianceName.ProfessionalRegistration
    ) {
      if (
        compliance.metadata?.registrationCertificate ||
        compliance.metadata?.registrationChecksFiles
      ) {
        if (compliance.metadata?.registrationCertificate) {
          downloadFile(compliance.metadata?.registrationCertificate);
        }
        if (compliance.metadata?.registrationChecksFiles) {
          downloadFile(compliance.metadata?.registrationChecksFiles);
        }
      } else {
        toast.error('No file exists');
      }
    } else if (compliance.taskName === EComplianceName.Qualifications) {
      if (compliance.metadata?.graduateTrainingFiles?.length) {
        compliance.metadata?.graduateTrainingFiles?.map((el) =>
          downloadFile(el),
        );
      } else {
        toast.error('No file exists');
      }
    } else if (compliance.taskName === EComplianceName.RightToWork) {
      if (compliance.metadata?.passportFiles?.length) {
        compliance.metadata?.passportFiles?.map((el) => downloadFile(el));
      } else {
        toast.error('No file exists');
      }
    } else if (compliance.taskName === EComplianceName.VerifiedIdentification) {
      if (
        compliance.metadata?.ids?.length ||
        compliance.metadata?.driverLicense?.length ||
        compliance.metadata?.proofAddress?.length
      ) {
        compliance.metadata?.ids?.map((el) => downloadFile(el));
        compliance.metadata?.driverLicense?.map((el) => downloadFile(el));
        compliance.metadata?.proofAddress?.map((el) => downloadFile(el));
      } else {
        toast.error('No file exists');
      }
    } else {
      toast.error('Compliance not found');
    }
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Task</th>
            <th>Reminder Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>Required</th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el, index) => (
                <tr key={el._id} data-testid={`compliance-row-${el._id}`}>
                  <td>
                    <div
                      className={`task-container ${!el.isRequired && 'disabled'}`}
                      data-testid={`task-container-${el._id}`}
                    >
                      <input
                        type="checkbox"
                        id={`task-checkbox-${el._id}`}
                        checked={el.isRequired && el.isValid}
                        disabled
                        data-testid={`checkbox-${el._id}`}
                      />
                      <label
                        htmlFor={`task-checkbox-${el._id}`}
                        data-testid={`task-label-${el._id}`}
                      >
                        {index + 1}. {el.taskName}
                      </label>
                    </div>
                  </td>
                  <td data-testid={`reminder-date-${el._id}`}>
                    {el.reminderDate ? formatDate(el.reminderDate) : '-'}
                  </td>
                  <td data-testid={`expiry-date-${el._id}`}>
                    {el.expiryDate ? formatDate(el.expiryDate) : '-'}
                  </td>
                  <td>
                    {!el.isRequired ? (
                      <ComplianceStatusBadge
                        status={'Not active'}
                        data-testid={`status-${el._id}`}
                      />
                    ) : (
                      <ComplianceStatus
                        value={el.status}
                        data-testid={`compliance-status-${el._id}`}
                      />
                    )}{' '}
                  </td>
                  <td>
                    <Switch
                      value={el.isRequired}
                      onClick={() => {
                        !isCRMApp && onUpdateRequired(el, !el.isRequired);
                      }}
                      data-testid={`switch-required-${el._id}`}
                    />
                  </td>
                  <td>
                    <div className="action-item">
                      {!isCRMApp && (
                        <>
                          {' '}
                          <div
                            className="download-icon"
                            onClick={() => downloadAllComplianceFile(el)}
                            data-testid={`download-icon-${el._id}`}
                          >
                            <DownloadIcon />
                          </div>
                          <div
                            className="edit-icon"
                            onClick={() => onClickEdit(el)}
                            data-testid={`edit-icon-${el._id}`}
                          >
                            <EditIcon />
                          </div>
                          <div
                            className="view-icon"
                            onClick={() =>
                              navigate(
                                `/${ERoute.CANDIDATES}/${id}/compliance/${el.taskName}`,
                              )
                            }
                            data-testid={`view-icon-${el._id}`}
                          >
                            <EyeIcon />
                          </div>
                        </>
                      )}
                      <div
                        className="delete-icon disabled"
                        data-testid={`delete-icon-${el._id}`}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
