import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../constants';

type ProgressBarProps = {
  value?: number;
};

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  gap: 10px;
  font-size: 14px;
  .progress {
    width: 70px;
    height: 7px;
    flex: 1;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 10px;
    .progress {
      width: 50px;
      height: 5px;
    }
  }
`;

export const ProgressBar = ({ value = 0 }: ProgressBarProps) => {
  const status = value < 30 ? 'danger' : value < 100 ? 'warning' : 'success';

  return (
    <ProgressBarContainer data-testid="progress-bar-container">
      <div className="progress" data-testid="progress-bar-wrapper">
        <div
          className={`progress-bar bg-${status}`}
          role="progressbar"
          style={{ width: Math.ceil(value) + '%' }}
          data-testid="progress-bar"
        />
      </div>
      <span data-testid="progress-percentage">{Math.ceil(value)}%</span>
    </ProgressBarContainer>
  );
};
