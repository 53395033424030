import React, { useState } from 'react';
import { ConfirmModalContainer } from './confirm-modal.style';
import { CancelIcon } from '../../../../icons';
import { Button, TitlePage } from '../../../ui';

type ConfirmModalProps = {
  isOpen: boolean;
  title: string;
  children?: any;
  onConfirm: () => void;
  onNegativeBtnClick: () => void;
};

export const ConfirmModal = ({
  isOpen,
  title,
  children,
  onConfirm,
  onNegativeBtnClick,
}: ConfirmModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickCancel = async () => {
    onNegativeBtnClick();
  };

  const onClickConfirm = async () => {
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ConfirmModalContainer data-testid="confirm-modal-container">
      <div
        className="confirmation-container"
        data-testid="confirmation-container"
      >
        <div className="confirmation-header" data-testid="confirmation-header">
          <TitlePage data-testid="modal-title">{title}</TitlePage>
          <CancelIcon onClick={onClickCancel} data-testid="cancel-icon" />
        </div>
        <div className="confirmation-body" data-testid="confirmation-body">
          {children}
        </div>
        <div className="confirmation-footer" data-testid="confirmation-footer">
          <Button
            type="danger"
            onClick={onClickConfirm}
            data-testid="positive-button"
            disabled={isSubmitting}
          >
            {title}
          </Button>
          <Button
            onClick={onClickCancel}
            data-testid="negative-button"
            type="primary"
            variant="outlined"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmModalContainer>
  );
};
