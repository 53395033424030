import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ConfirmModal } from '../components';

type ConfirmContextType = {
  openConfirm: (modalConfig: ConfirmConfig) => void;
  closeConfirm: () => void;
};

type ConfirmConfig = {
  title: string;
  component: ReactNode;
  onConfirm: () => void;
};

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [modalConfig, setConfirmConfig] = useState<ConfirmConfig | null>(null);

  const openConfirm = (config: ConfirmConfig) => {
    setConfirmConfig(config);
  };

  const closeConfirm = () => {
    setConfirmConfig(null);
  };

  return (
    <ConfirmContext.Provider value={{ openConfirm, closeConfirm }}>
      {children}

      {modalConfig && (
        <ConfirmModal
          isOpen={!!modalConfig}
          title={modalConfig.title}
          onConfirm={modalConfig.onConfirm}
          onNegativeBtnClick={() => closeConfirm()}
        >
          {modalConfig.component}
        </ConfirmModal>
      )}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context;
};
