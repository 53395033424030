import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  min-width: 300px;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  .header {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 20px;
    }
    .selection {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  .labels-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .label {
      display: flex;
      justify-content: space-between;
      > div:nth-child(1) {
        color: #687588;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        > div:nth-child(1) {
          width: 14px;
          height: 14px;
          border-radius: 7px;
        }
      }

      > div:nth-child(2) {
        font-weight: 600;
      }
    }

    .show-more {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: gray;
      cursor: pointer;

      &:hover {
        color: black;
        .chevron {
          stroke: black;
        }
      }

      .chevron {
        stroke: gray;
      }
    }
  }
`;
