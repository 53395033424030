import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const TabPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .tab-items-list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #f1f2f4;
    .tab-item {
      padding: 10px 20px;

      &.disabled {
        cursor: not-allowed;
        color: gray;
        border: unset !important;
      }
    }
  }
  .tab-content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .info-card {
      border-radius: 16px;
      border: 1px solid #f1f2f4;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .availability-actions-container {
        display: flex;
        align-items: center;
        gap: 20px;
        input {
          max-width: 100%;
        }
        svg {
          cursor: pointer;
        }
        input,
        select {
          min-width: 200px;
        }
      }
      .info-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .info-card-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      hr {
        background: #f1f2f4;
        opacity: 1;
      }
      .info-card-content {
        gap: 16px;
        .info-item-title {
          color: #687588;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
        .info-item-data {
          color: #111827;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 15px;
    .tab-items-list {
      .tab-item {
        padding: 5px 10px;
      }
    }
    .tab-content-container {
      gap: 15px;
      .info-card {
        padding: 16px;
        gap: 15px;
        font-size: 14px;
        .availability-actions-container {
          gap: 14px;
          display: flex;
          flex-wrap: wrap;
          input,
          select {
            min-width: 100%;
          }
        }
        hr {
          margin: 0;
        }

        .info-card-title {
          font-size: 14px;
        }
        .info-card-content {
          gap: 10px;
          .info-item-title {
            font-size: 10px;
          }
          .info-item-data {
            font-size: 10px;
          }
        }
      }
    }
  }
`;

export const InfoCardRow = ({
  title,
  data,
  dataTestId,
}: {
  title: string;
  data: React.ReactNode;
  dataTestId: string;
}) => (
  <div className="row">
    <div className="info-item-title col-4" data-testid={`${dataTestId}-label`}>
      {title}
    </div>
    <div className="info-item-data col-8" data-testid={`${dataTestId}-value`}>
      {data || '-'}
    </div>
  </div>
);
