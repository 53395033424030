import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ResetPasswordDto } from '../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  ResetPasswordSchema,
} from '../../utils';
import { Button, FieldText, TitlePage } from '../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { AppIcon } from '../../icons';

interface ResetPasswordDtoUI extends ResetPasswordDto {
  confirmPassword: string;
}

export const ResetPasswordPage = () => {
  const { token } = useParams();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordDtoUI>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSent, setIsSent] = useState(false);

  const onClickResetPassword = async () => {
    const values = getValues();
    await AxiosInstance.auth
      .authControllerResetPassword(
        { token: token ?? '' },
        { password: values.password },
      )
      .then((response) => {
        toast.success(
          'Your password has been successfully reset. You can now log in with your new password.',
        );
        setIsSent(true);
      })
      .catch((e: AxiosInstanceErrorResponse) => {
        if (e.status === 401) {
          setErrorMessage('Wrong Token please check your credentials');
          setError('password', { message: '' });
          setError('confirmPassword', { message: '' });
        }
        if (e.status === 400) {
          setErrorMessage('Wrong Token please check your credentials');
          setError('password', { message: '' });
          setError('confirmPassword', { message: '' });
        }
      });
  };

  return (
    <div className="content-container" data-testid="content-container">
      <div className="mobile-logo-container">
        <AppIcon data-testid="app-icon" />
      </div>
      <TitlePage className="text-center" data-testid="title-page">
        Reset your account
      </TitlePage>
      <br />
      <br />
      <br />
      <br />
      <div className="email-container" data-testid="password-container">
        <div className="input-container" data-testid="password-input-container">
          <FieldText
            placeholder="Enter your password"
            type="password"
            label="Password"
            register={register('password', { required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(onClickResetPassword)();
            }}
            error={errors.password}
            data-testid="password-input"
          />
        </div>
      </div>
      <div className="email-container" data-testid="confirm-password-container">
        <div
          className="input-container"
          data-testid="confirm-password-input-container"
        >
          <FieldText
            placeholder="Confirm your password"
            type="password"
            label="Confirm Password"
            register={register('confirmPassword', { required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(onClickResetPassword)();
            }}
            error={errors.confirmPassword}
            data-testid="confirm-password-input"
          />
        </div>
      </div>
      {errorMessage && (
        <span
          style={{
            color: 'red',
            fontSize: '12px',
          }}
          data-testid="error-message"
        >
          {errorMessage}
        </span>
      )}
      {isSent ? (
        <div className="register-option" data-testid="reset-success-message">
          Your password has been successfully reset. You can now log in with
          your new password. <br />
          Click{' '}
          <Link to={'/'} data-testid="login-link">
            Here
          </Link>{' '}
          to login.
        </div>
      ) : (
        <Button
          type="primary"
          onClick={handleSubmit(onClickResetPassword)}
          data-testid="reset-password-button"
        >
          Reset password
        </Button>
      )}

      <div className="cancel-container " data-testid="cancel-container">
        <Link
          to={`/${ERoute.LOGIN}`}
          className="cancel-link"
          data-testid="cancel-link"
        >
          Click here to cancel
        </Link>
      </div>
    </div>
  );
};
