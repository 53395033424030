import React from 'react';
import styled from 'styled-components';
import { LeftArrowIcon } from '../../icons';
import { useNavigate } from 'react-router-dom';

const BackActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #111827;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }

  path {
    fill: #111827;
  }
`;

export const BackAction = () => {
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <BackActionContainer
      onClick={onClickBack}
      data-testid="back-action-container"
    >
      <LeftArrowIcon /> Back
    </BackActionContainer>
  );
};
