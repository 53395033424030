import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuthLayoutContainer } from './auth-layout.style';
import { AppIcon } from '../../../icons';

export const AuthLayout = () => {
  return (
    <AuthLayoutContainer data-testid="login-container">
      <div className="left-container" data-testid="left-container">
        <div className="image-container" data-testid="image-container"></div>
        <div className="bottom-container" data-testid="bottom-container">
          <AppIcon isWhiteColor data-testid="app-icon" />
          <div className="title" data-testid="login-title">
            Let’s empower your <br /> candidates today.
          </div>
          <div className="subtitle" data-testid="login-subtitle">
            We help to complete all your conveyancing needs easily
          </div>
        </div>
      </div>
      <div className="right-container" data-testid="right-container">
        <div className="top-container" data-testid="top-container"></div>
        <Outlet data-testid="outlet" />
        <div className="bottom-container" data-testid="bottom-footer-container">
          <div className="copyright" data-testid="copyright">
            ©{new Date().getFullYear()} Careo. All rights reserved.
          </div>
          <Link to={''} data-testid="terms-conditions-link">
            Terms & Conditions
          </Link>{' '}
          <a
            href={'https://www.careo.ai/privacy-policy'}
            target="_blank"
            data-testid="privacy-policy-link"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </AuthLayoutContainer>
  );
};
