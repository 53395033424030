import styled from 'styled-components';

export const EmailsPageContainer = styled.div`
  flex: 1;
  gap: 16px !important;
  position: relative;

  .email-linking-btn-container {
    width: 50%;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid #8080802e;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        color: #0d6efd;
        border: 1px solid gray;
        text-decoration: underline;
      }
    }
  }

  .email-linked {
    border: 1px solid red;
  }

  .blurred-background {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 20px;
    color: #333;
  }

  .email-not-linked {
    flex: 1;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .emails-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    background: white;
    padding: 10px 0;
    border-radius: 10px;
    .left-filter {
      display: flex;
      gap: 20px;
      padding: 0px 24px;
      .filter-item {
        padding: 10px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        padding: 10px 5px;
        cursor: pointer;
        &:hover {
          color: #27a376;
          opacity: 0.9;
        }
        &.active {
          color: #27a376;
          border-bottom: 2px solid #27a376;
        }
      }
    }
    .right-filter {
      display: flex;
      gap: 20px;
      padding: 0px 24px;
      flex-wrap: wrap;
      .search-input,
      .select-container {
        width: 160px;
        border-radius: 10px;
        border: 1px solid #ced0d5;
        input {
          background-color: unset;
        }
      }
    }
  }

  .emails-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    flex: 1;
    max-height: 76vh;
    height: 76vh;
    gap: 16px;
    background: unset;

    .left-container {
      flex: 1;
    }
    .right-container {
      flex: 3;
    }

    .messages-list,
    .message-data {
      background-color: white;
    }
  }
`;
