import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SideModal } from '../components';

type ModalContextType = {
  openModal: (modalConfig: ModalConfig) => void;
  closeModal: () => void;
};

type ModalConfig = {
  title?: string;
  component: ReactNode;
  actions?: {
    label: string;
    action: () => void;
    'data-testid'?: string;
  }[];
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  const openModal = (config: ModalConfig) => {
    setModalConfig(config);
  };

  const closeModal = () => {
    setModalConfig(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}

      {modalConfig && (
        <SideModal
          isOpen={!!modalConfig}
          setIsOpen={closeModal}
          title={modalConfig.title}
          actions={modalConfig.actions}
        >
          {modalConfig.component}
        </SideModal>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
