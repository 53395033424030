import React from 'react';
import { LeftArrowIcon, RightArrowIcon } from '../../icons';
import styled from 'styled-components';
import { breakpoint } from '../../constants';
import usePagination from '@mui/material/usePagination';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .navigation-container {
    display: flex;
    gap: 5px;

    .right-arrow,
    .left-arrow {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f1f2f4;
      cursor: pointer;

      svg path {
        fill: #111827;
      }

      &.disabled {
        cursor: no-drop;
        svg path {
          fill: #a0aec0;
        }
      }
    }

    .page-number {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: #f8f8f8;
      }
    }
  }

  .information-container {
    color: #687588;
    font-size: 12px;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 10px;
    flex-direction: column;
    gap: 5px;

    .navigation-container {
      gap: 5px;
      .right-arrow,
      .left-arrow {
        width: 20px;
        height: 20px;
      }

      .page-number {
        width: 20px;
        height: 20px;
      }
    }

    .information-container {
      font-size: 8px;
    }
  }
`;

type PaginationProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  totalEntries: number;
  itemsPerPage: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalPages,
  totalEntries,
  itemsPerPage,
}) => {
  // Use MUI's usePagination hook
  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
    onChange: (event, newPage) => setCurrentPage(newPage),
    siblingCount: 1,
    boundaryCount: 1,
  });

  // Calculate start and end entry indexes
  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, totalEntries);

  if (!totalEntries) {
    return <></>;
  }

  return (
    <PaginationContainer
      className="pagination-container"
      data-testid="pagination-container"
    >
      <div className="navigation-container" data-testid="pagination-navigation">
        {items.map(({ page, type, selected, ...item }, index) => {
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            return (
              <span
                key={index}
                className="page-number"
                data-testid={`ellipsis-${type}`}
              >
                ...
              </span>
            );
          }
          if (type === 'page') {
            return (
              <div
                key={index}
                className={`page-number ${selected ? 'active' : ''}`}
                onClick={() => setCurrentPage(page ?? 1)}
                data-testid={`page-${page}`}
              >
                {page}
              </div>
            );
          }
          if (type === 'previous') {
            return (
              <div
                key={index}
                className={`left-arrow ${currentPage <= 1 && 'disabled'}`}
                onClick={() =>
                  currentPage > 1 && setCurrentPage(currentPage - 1)
                }
                data-testid="previous-arrow"
              >
                <LeftArrowIcon />
              </div>
            );
          }

          if (type === 'next') {
            return (
              <div
                key={index}
                className={`right-arrow ${currentPage >= totalPages && 'disabled'}`}
                onClick={() =>
                  currentPage < totalPages && setCurrentPage(currentPage + 1)
                }
                data-testid="next-arrow"
              >
                <RightArrowIcon />
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="information-container" data-testid="pagination-info">
        Showing {startEntry} to {endEntry} of {totalEntries} entries
      </div>
    </PaginationContainer>
  );
};

export default Pagination;
