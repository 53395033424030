import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  NewClientForm,
  Pagination,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UpdateClientForm,
  UploadButton,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../../icons';
import {
  getCountiesOfSelectedRegions,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterClient,
  filterClients,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortClients,
  uploadClientsCsvRequest,
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Client, EUserRole, Trust } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';

const itemsPerPage = 8;

export const ClientsListPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');

  const { user } = useAuth();

  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { openConfirm, closeConfirm } = useConfirm();
  const onDeleteConfirm = (item: Client) => {
    openConfirm({
      title: 'Delete Client',
      component: (
        <>
          Do you want to delete <b>{item?.clientName}</b>
        </>
      ),
      onConfirm: () => deleteClient(item),
    });
  };

  const [filter, setFilter] = useState<TFilterClient>({
    search: '',
    regions: [],
    counties: [],
    trusts: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Client',
      component: (
        <NewClientForm
          trustsList={trustsList}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getClients();
            closeModal();
          }}
          data-testid="new-client-form"
        />
      ),
    });
  };

  const onClickEdit = (client: Client) => {
    openModal({
      title: 'Update Client',
      component: (
        <UpdateClientForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getClients();
            closeModal();
          }}
          selectedClient={client}
          data-testid="update-client-form"
        />
      ),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CLIENTS}/${id}`);
  };

  const uploadClientsCsv = async (file: File) => {
    await uploadClientsCsvRequest(file)
      .then(() => {
        getClients();
        toast.success('Clients uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    setIsLoading(true);
    await AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        let result = response.data.items;
        if (isProfileCompleteQuery === 'false') {
          result = result.filter((el) => !el.isProfileComplete);
        }
        setClients(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteClient = async (recordToDelete: Client) => {
    await AxiosInstance.clients
      .clientsControllerDeleteClient(recordToDelete?._id)
      .then(() => {
        toast.success('Client removed successfully');
        getClients();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(clientsList, currentPage, itemsPerPage);
  }, [clientsList, currentPage]);

  useEffect(() => {
    const filteredData = filterClients(clients, filter);
    const sortedData = sortClients(filteredData, sort);
    setClientsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [clients, filter, sort]);

  useEffect(() => {
    getClients();
    getTrusts();
  }, []);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Clients',
            url: ERoute.CLIENTS,
          },
          {
            title: 'Trust',
            url: ERoute.TRUSTED_CLIENTS,
          },
        ]}
        data-testid="tab-filter"
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="clients-title">Clients</TitlePage>
            <SubTitlePage data-testid="clients-subtitle">
              Manage your Client
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadClientsCsv}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={onClickCreate}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search client"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
            data-testid="region-select"
          />
          <MultipleSelect
            placeholder="All Counties"
            options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
              (el) => ({ label: el, value: el }),
            )}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                counties: values ?? [],
              }));
            }}
            disabled={!filter.regions.length && !filter.counties.length}
            data-testid="county-select"
          />
          <MultipleSelect
            placeholder="All Trusts"
            options={trustsList.map((el) => ({
              label: el.name,
              value: el._id,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                trusts: values ?? [],
              }));
            }}
            data-testid="trust-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="clients-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('clientName', setSort)}
                  data-testid="sort-client-name"
                >
                  <div>
                    <label>Client Name</label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="sort-lead-contact"
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user-firstName', setSort)}
                  data-testid="sort-line-manager"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="sort-county"
                >
                  <div>
                    <label>County</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="sort-region"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('zip', setSort)}
                  data-testid="sort-zip"
                >
                  <div>
                    <label>Postcode</label>
                    <SortIcon value={sort.key === 'zip' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('isProfileComplete', setSort)}
                  data-testid="sort-profile"
                >
                  <div>
                    <label>Profile</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th data-testid="action-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((client) => {
                        const isAdmin = user?.role === EUserRole.Admin;
                        const isClientBelongsToUser =
                          client?.user?._id === user?._id || isAdmin;

                        return (
                          <tr
                            key={client._id}
                            data-testid={`client-row-${client._id}`}
                          >
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            <td data-testid={`client-name-${client._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{client.clientName}</div>
                                  <div className="email">
                                    {client.trust?.name ?? '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`lead-contact-${client._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {client.firstName} {client.lastName}
                                  </div>
                                  <div className="email">{client.email}</div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`line-manager-${client._id}`}>
                              {`${client.user?.firstName} ${client.user?.lastName}`}
                            </td>
                            <td data-testid={`county-${client._id}`}>
                              {client.address?.county || '-'}
                            </td>
                            <td data-testid={`region-${client._id}`}>
                              {client.address?.region || '-'}
                            </td>
                            <td data-testid={`zip-${client._id}`}>
                              {client.address?.zip || '-'}
                            </td>
                            <td data-testid={`profile-status-${client._id}`}>
                              <Badge
                                type={
                                  client.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {client.isProfileComplete
                                  ? 'Complete'
                                  : 'Incomplete'}
                              </Badge>
                            </td>
                            <td data-testid={`action-icons-${client._id}`}>
                              <div className="action-item">
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(client._id)}
                                  data-testid={`view-client-${client._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className={`edit-icon ${!isClientBelongsToUser && 'disabled'}`}
                                  onClick={() =>
                                    isClientBelongsToUser && onClickEdit(client)
                                  }
                                  data-testid={`edit-client-${client._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className={`delete-icon ${!isAdmin && 'disabled'}`}
                                  onClick={() =>
                                    isAdmin && onDeleteConfirm(client)
                                  }
                                  data-testid={`delete-client-${client._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-found"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={clientsList.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
