import React, { useEffect, useMemo, useState } from 'react';
import {
  TimesheetActionContainer,
  TimesheetContainer,
} from './timesheet.style';
import { AppIcon, DeleteIcon } from '../../icons';
import { Button, FieldText, Select, Table } from '../../components';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDate,
  getTotalWorkingHours,
} from '../../utils';
import { durations } from '../../constants';
import {
  AddTimingsDto,
  ETimesheetStatus,
  Timesheet,
} from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const TimesheetCandidatePage = () => {
  const { token } = useParams();

  const [timings, setTimings] = useState<AddTimingsDto[]>([]);
  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const totalOfWorkingHours: string = useMemo(() => {
    return getTotalWorkingHours(timings);
  }, [timings]);

  const getTimesheetDetails = async () => {
    try {
      const result = (
        await AxiosInstance.timesheetMember.timesheetMemberControllerFindOneByToken(
          token!,
        )
      ).data;
      if (result.status === ETimesheetStatus.Rejected) {
        result.status = ETimesheetStatus.Generated;
      }
      setTimings((result.timings ?? []) as AddTimingsDto[]);
      setTimesheet(result);
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickSave = async () => {
    AxiosInstance.timesheetMember
      .timesheetMemberControllerAddTimingsByCandidateToken(token!, {
        timings,
      })
      .then(() => {
        toast.success('Timesheet sent successfully');
        getTimesheetDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickRemoveDay = (index: number, dayName: string) => {
    setTimings((prev) => {
      const result = prev.filter((el, index2) => index2 !== index);
      return [...result];
    });
    toast.warning(`${dayName} has been deleted`);
  };

  useEffect(() => {
    getTimesheetDetails();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (!timesheet) {
    return <>Timesheet not found</>;
  }

  return (
    <>
      <TimesheetContainer
        className="container"
        id="container"
        data-testid="timesheet-container"
      >
        <div className="logo-container" data-testid="logo-container">
          <AppIcon />
        </div>
        {timesheet.status === 'Rejected' && (
          <div className="title" data-testid="timesheet-rejected">
            Timesheet rejected
          </div>
        )}
        <div className="title" data-testid="timesheet-status">
          {timesheet.status === 'Generated'
            ? 'Please fill in your Agency Locum Weekly timesheet'
            : 'Timesheet is already submitted'}
        </div>
        <div className="title" data-testid="timesheet-title">
          Timesheet
        </div>
        <FieldText
          label="Placement"
          value={
            timesheet?.placement.job.client.clientName +
            ' - ' +
            timesheet?.placement.job.specialty
          }
          disabled
          data-testid="placement-field-text"
        />
        <div className="subtitle" data-testid="personal-details-title">
          Personal Details
        </div>
        <div
          className="personal-info-container"
          data-testid="personal-info-container"
        >
          <div className="personal-name" data-testid="personal-name">
            {timesheet?.placement?.candidate?.title}{' '}
            {timesheet?.placement?.candidate?.firstName}{' '}
            {timesheet?.placement?.candidate?.lastName}
          </div>
          <div className="row" data-testid="personal-info-row">
            <div className="col-md-6 row">
              <div
                className="info-item-title col-5"
                data-testid="specialty-title"
              >
                Specialty
              </div>
              <div
                className="info-item-data col-7"
                data-testid="specialty-data"
              >
                {timesheet?.placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div
                className="info-item-title col-5"
                data-testid="department-title"
              >
                Department
              </div>
              <div
                className="info-item-data col-7"
                data-testid="department-data"
              >
                {timesheet?.placement?.candidate?.department || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div
                className="info-item-title col-5"
                data-testid="week-commencing-title"
              >
                Week Commencing
              </div>
              <div
                className="info-item-data col-7"
                data-testid="week-commencing-data"
              >
                {formatDate(timesheet?.placement?.availableFrom ?? '')}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5" data-testid="team-title">
                Team
              </div>
              <div className="info-item-data col-7" data-testid="team-data">
                {timesheet?.placement?.job.specialty}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5" data-testid="email-title">
                Email Address
              </div>
              <div className="info-item-data col-7" data-testid="email-data">
                {timesheet?.placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5" data-testid="grade-title">
                Grade
              </div>
              <div className="info-item-data col-7" data-testid="grade-data">
                {timesheet?.placement?.job?.grade}
              </div>
            </div>
          </div>
        </div>
        <div className="subtitle" data-testid="update-timesheet-title">
          Update Timesheet below
        </div>
        <div className="text" data-testid="breaks-info-text">
          Breaks Must be recorded in the same detail as the Assignment
          confirmation
        </div>
        <div
          className="accordion"
          id="accordionPanelsStayOpenExample"
          data-testid="accordion-container"
        >
          {timings?.map((el, index) => {
            const dayName = `${new Date(el.date).toLocaleDateString('en-US', {
              weekday: 'long',
            })}- ${formatDate(el.date)}`;
            return (
              <div
                className="accordion-item"
                key={index}
                data-testid={`accordion-item-${index}`}
              >
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-${index}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-${index}`}
                    data-testid={`accordion-button-${index}`}
                  >
                    {dayName}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                  data-testid={`accordion-collapse-${index}`}
                >
                  <div
                    className="accordion-body"
                    data-testid={`accordion-body-${index}`}
                  >
                    <div className="info-card-content row mb-5">
                      <Table data-testid={`timesheet-table-${index}`}>
                        <thead>
                          <tr>
                            <th>Working Day</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start time</td>
                            <td>
                              <FieldText
                                type="time"
                                onChange={(e) =>
                                  setTimings((prev) => {
                                    prev[index].startTime = e.target.value;
                                    return [...prev];
                                  })
                                }
                                value={timings[index].startTime}
                                disabled={timesheet.status !== 'Generated'}
                                data-testid={`start-time-${index}`}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Finish time</td>
                            <td>
                              <FieldText
                                type="time"
                                onChange={(e) =>
                                  setTimings((prev) => {
                                    prev[index].endTime = e.target.value;
                                    return [...prev];
                                  })
                                }
                                value={timings[index].endTime}
                                disabled={timesheet.status !== 'Generated'}
                                data-testid={`finish-time-${index}`}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>On call hours</td>
                            <td>
                              <Select
                                placeholder="Select on call hours"
                                options={[
                                  {
                                    label: 'No on call hours',
                                    value: 0,
                                  },
                                  ...durations,
                                ]}
                                onChange={(value) => {
                                  setTimings((prev) => {
                                    prev[index].callHours = Number(value ?? 0);
                                    return [...prev];
                                  });
                                }}
                                value={timings[index].callHours}
                                disabled={timesheet.status !== 'Generated'}
                                data-testid={`on-call-hours-${index}`}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Breaks </td>
                            <td>
                              <Select
                                placeholder="Select breaks duration"
                                options={[
                                  {
                                    label: 'No breaks',
                                    value: 0,
                                  },
                                  ...durations,
                                ]}
                                onChange={(value) => {
                                  setTimings((prev) => {
                                    prev[index].breakTime = Number(value ?? 0);
                                    return [...prev];
                                  });
                                }}
                                value={timings[index].breakTime}
                                disabled={timesheet.status !== 'Generated'}
                                data-testid={`breaks-${index}`}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-end">
                        {timesheet.status === 'Generated' &&
                          timings.length > 1 && (
                            <Button
                              type="danger"
                              onClick={() => onClickRemoveDay(index, dayName)}
                              data-testid={`remove-day-button-${index}`}
                            >
                              <DeleteIcon />
                              Remove this day
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </TimesheetContainer>

      <TimesheetActionContainer
        className="container"
        data-testid="timesheet-action-container"
      >
        <div className="left-container" data-testid="total-working-time">
          Total Working Time : <label>{totalOfWorkingHours}</label>
        </div>
        {timesheet.status === 'Generated' && (
          <div
            className="right-container"
            onClick={onClickSave}
            data-testid="save-button-container"
          >
            <Button type="primary" data-testid="save-button">
              Save
            </Button>
          </div>
        )}
      </TimesheetActionContainer>
    </>
  );
};

export default TimesheetCandidatePage;
