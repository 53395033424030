import React, { useEffect, useRef } from 'react';

interface CheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  indeterminate,
  onChange,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.indeterminate) {
      event.target.checked = true;
    }
    onChange(event);
  };

  useEffect(() => {
    if (checkboxRef.current) {
      if (indeterminate) {
        checkboxRef.current.indeterminate = indeterminate;
      } else {
        checkboxRef.current.indeterminate = false;
      }
      checkboxRef.current.checked = checked;
    }
  }, [checked, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={checkboxRef}
      onChange={onChangeValue}
      checked={checked}
      data-testid="checkbox-input"
    />
  );
};
