import { PayrollsListPage, PayrollDetailsPage } from '../pages/payroll';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const PayrollsRoutes: IRoute[] = [
  {
    path: PagesUrls.PAYROLLS.Index,
    element: <PayrollsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: PagesUrls.PAYROLLS.Details,
    element: <PayrollDetailsPage />,
  },
];
