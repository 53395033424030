import React from 'react';
import styled from 'styled-components';
import { WeekData } from './weeks-slider';
import { Button } from '../../ui';
import { DotsIcon, PlusIcon } from '../../../icons';
import { RotasCard } from './rotas-card';

const data = [
  {
    role: 'Surgeon',
    days: [
      { status: 'Complete', name: 'Gemma Flowerdew', time: '8:00 - 16:00' },
      { status: 'Filled', name: 'Gemma Flowerdew', time: '8:00 - 16:00' },
      { status: 'Confirm', name: 'Gemma Flowerdew', time: '8:00 - 16:00' },
    ],
  },
  {
    role: 'Anethetists',
    days: [
      { status: 'Complete', name: 'Rami Salha', time: '8:00 - 16:00' },
      { status: 'Filled', name: 'Gemma Flowerdew', time: '8:00 - 16:00' },
      { status: 'Confirm', name: 'Rami Salha', time: '8:00 - 16:00' },
    ],
  },
  {
    role: 'Nurse',
    days: [
      {
        status: 'Complete',
        name: 'Magdalena Pawelowska',
        time: '8:00 - 16:00',
      },
      { status: 'Cancelled', name: 'Gemma Flowerdew', time: '8:00 - 16:00' },
      {
        status: 'Filled',
        name: 'Magdalena Pawelowska',
        time: '8:00 - 16:00',
      },
    ],
  },
  {
    role: 'Select Role',
    days: [{ status: 'Vacant', name: '-', time: '8:00 - 16:00' }],
  },
];

const RotasWeekCalendarContainer = styled.div`
  font-family: Arial, sans-serif;
  overflow-x: auto;

  .schedule-table {
    width: 100%;
    border-collapse: collapse;

    thead th.day-item {
      border-image: linear-gradient(
          transparent 10%,
          #e0e0ed 10% 75%,
          transparent 50%
        )
        0 0 0 1 / 1px;
    }

    tbody tr.data-row {
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      td {
        border-right: 1px solid #e0e0e0;
      }
    }

    th {
      &.today {
        background-color: #f5fbf6;
      }
    }

    td,
    th {
      width: 340px;
    }

    .days-row,
    .theatre-row,
    .data-row {
      display: flex;
      .new-activity,
      .theatre-title,
      .role-cell {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
      }
    }

    .days-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .day-divider {
        border-radius: 1px;
        background: #e5e8eb;
        width: 1px;
        height: 40px;
      }

      th,
      td {
        font-weight: bold;
        text-align: center;
        flex: 1;
        height: 100%;
        padding: 12px;

        &.new-activity {
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: space-between;
          .project-name {
            font-size: 14px;
          }
          > .button {
            padding: 5px 12px;
            gap: 5px;
          }
        }

        .day-number {
          font-size: 18px;
          font-weight: 500;
        }
        .day-name {
          color: #9d9ca3;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .theatre-row {
      background-color: #f5fbf6;
      .theatre-title {
        background-color: #f5fbf6;
        font-size: 12px;
        padding: 6px 20px;
        font-weight: 600;
      }
      .theatre-empty-cell {
      }
    }

    .data-row {
      .role-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg path {
          fill: #a0aec0;
        }
      }
      .day-cell {
        padding: 3px;
      }
    }

    .header-row,
    .row {
    }
    .day-cell,
    .role-cell {
      padding: 16px;
    }
  }
`;

type RotasWeekCalendarProps = {
  selectedWeek: WeekData;
};

export const RotasWeekCalendar = ({ selectedWeek }: RotasWeekCalendarProps) => {
  // Generate the days of the week based on the selected week's starting day
  const daysOfTheWeek = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(selectedWeek.startDate);
    day.setDate(day.getDate() + i);
    const isToday = day.toDateString() === new Date().toDateString();
    const dayName = day.toLocaleDateString('en-GB', { weekday: 'short' });
    const dayNumber = day.getDate();
    return { day, dayName, dayNumber, isToday };
  });

  return (
    <RotasWeekCalendarContainer
      className="rotas-week-calendar"
      data-testid="rotas-week-calendar"
    >
      <table className="schedule-table" data-testid="schedule-table">
        <thead>
          <tr className="days-row" data-testid="days-row">
            <th className="new-activity" data-testid="new-activity">
              <div className="project-name" data-testid="project-name">
                Addenbrookes OMFS
              </div>
              <Button
                type="success"
                variant="outlined"
                data-testid="add-activity-button"
              >
                <PlusIcon /> Add Activity
              </Button>
            </th>
            {daysOfTheWeek.map((el, index) => {
              return (
                <th
                  className={`day-item ${el.isToday ? 'today' : ''}`}
                  key={index}
                  data-testid={`day-item-${index}`}
                >
                  <div
                    className="day-number"
                    data-testid={`day-number-${index}`}
                  >
                    {el.dayNumber}
                  </div>
                  <div className="day-name" data-testid={`day-name-${index}`}>
                    {el.dayName}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="theatre-row" data-testid="theatre-row">
            <td className="theatre-title" data-testid="theatre-title">
              Theatre
            </td>
          </tr>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="data-row"
              data-testid={`data-row-${rowIndex}`}
            >
              <td className="role-cell" data-testid={`role-cell-${rowIndex}`}>
                <div
                  className="role-name"
                  data-testid={`role-name-${rowIndex}`}
                >
                  {row.role}
                </div>
                <div
                  className="role-action"
                  data-testid={`role-action-${rowIndex}`}
                >
                  <DotsIcon />
                </div>
              </td>
              {daysOfTheWeek.map((status, dayIndex) => (
                <td
                  key={dayIndex}
                  className="day-cell"
                  data-testid={`day-cell-${rowIndex}-${dayIndex}`}
                >
                  <RotasCard
                    data-testid={`rotas-card-${rowIndex}-${dayIndex}`}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </RotasWeekCalendarContainer>
  );
};
