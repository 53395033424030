import React, { useEffect, useState } from 'react';
import { Button, FieldText, FormContainer, Select } from '../ui';
import {
  Candidate,
  CreateApplicationDto,
  DurationDto,
  Job,
} from '../../backend/careo-api';
import {
  AddCandidateVacancySchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../utils';
import { useForm } from 'react-hook-form';
import { applicationStatusList } from '../../constants';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { MinusIcon, PlusIcon } from '../../icons';
import { handleFormsServerErrors } from '../../utils';

type AddToVacancyFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidate: Candidate;
  vacancies: Job[];
};

export const AddToVacancyForm = ({
  onCancel,
  onSuccess,
  candidate,
  vacancies,
}: AddToVacancyFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(AddCandidateVacancySchema as any),
  });

  const [durations, setDurations] = useState<DurationDto[]>([
    {} as DurationDto,
  ]);

  const onClickAddDuration = () => {
    setDurations((prev) => [...prev, {} as DurationDto]);
  };

  const onClickDeleteDuration = (index: number) => {
    if (durations.length <= 1) {
      return;
    }
    setDurations(() => {
      const tempDurations = getValues('durations');
      tempDurations.splice(index, 1);
      setValue('durations', tempDurations);
      const durationErrors = errors?.durations ?? [];
      durationErrors.splice?.(index, 1);
      setError('durations', durationErrors as any);
      return [...tempDurations];
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    setIsSubmitting(true);
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerCreate({
        ...values,
        candidateId: candidate._id,
      })
      .then(() => {
        toast.success('Candidate added to vacancy successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setValue('candidateId', candidate._id);
  }, [candidate]);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        label="Candidate Name"
        required
        value={candidate.firstName + ' ' + candidate.lastName}
        disabled
        data-testid="candidate-name-field"
      />
      <Select
        placeholder="Select Vacancy"
        label="Vacancy"
        options={vacancies.map((el) => ({
          label: el.grade + ' / ' + el.specialty,
          value: el._id,
        }))}
        register={register('jobId', { required: true })}
        error={errors.jobId}
        data-testid="vacancy-select"
      />
      <Select
        options={applicationStatusList}
        placeholder="Option"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
        data-testid="status-select"
      />
      {durations.map((_, index) => (
        <div className="row" key={index} data-testid={`duration-row-${index}`}>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Start Date"
              required
              type="date"
              register={register(`durations.${index}.availableFrom`)}
              error={errors.durations?.[index]?.availableFrom}
              data-testid={`start-date-field-${index}`}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="End Date"
              required
              type="date"
              register={register(`durations.${index}.availableTo`)}
              error={errors.durations?.[index]?.availableTo}
              data-testid={`end-date-field-${index}`}
            />
          </div>
          <div className="col-md-2 d-flex align-items-center delete-icon">
            <Button
              type="danger"
              onClick={() => onClickDeleteDuration(index)}
              disabled={durations.length <= 1}
              data-testid={`delete-duration-btn-${index}`}
            >
              <MinusIcon data-testid={`minus-icon-${index}`} />
            </Button>
          </div>
        </div>
      ))}
      <div className="add-duration-container d-flex justify-content-start">
        <Button
          type="success"
          onClick={onClickAddDuration}
          data-testid="add-duration-btn"
        >
          <PlusIcon data-testid="plus-icon" /> Add New Duration
        </Button>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="submit-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
