import React, { useMemo, useState } from 'react';
import { Select, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Application, EApplicationStatus } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  PlacementCreateByApplicationIdSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { rejectedPlacementStatuses } from '../../pages/placement/placements-list/placements-list.page';

type NewPlacementFormProps = {
  applicationsList: Application[];
  onCancel: () => void;
  getPlacements: any;
};

export const NewPlacementForm = ({
  applicationsList,
  onCancel,
  getPlacements,
}: NewPlacementFormProps) => {
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<{ applicationId: string }>({
    resolver: yupResolver(PlacementCreateByApplicationIdSchema),
  });

  const onClickClose = () => {
    onCancel();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdateApplicationStatus(values.applicationId, {
        status: EApplicationStatus.Placement,
      })
      .then(() => {
        getPlacements();
        toast.success('Placement added successfully');
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  const applicationOptions = useMemo(
    () =>
      applicationsList
        .filter(
          (application) =>
            application.status !== EApplicationStatus.Placement &&
            application.status !== EApplicationStatus.Rejected &&
            !rejectedPlacementStatuses.includes(application.approvalStatus!),
        )
        .map((application) => ({
          label: `${application.job?.grade ?? 'N/A'} / ${application.job?.specialty ?? 'N/A'} : ${application.candidate?.firstName ?? ''} ${application.candidate?.lastName ?? ''} (${application.status})`,
          value: application._id,
        })),
    [],
  );
  return (
    <FormContainer data-testid="form-container">
      <Select
        placeholder="Select Application"
        label="Application"
        options={applicationOptions}
        register={register('applicationId')}
        required
        error={errors.applicationId}
        data-testid="application-select"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
