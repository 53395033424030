import React, { useMemo } from 'react';
import { Table } from '../ui';
import { useParams } from 'react-router-dom';
import { DeleteIcon } from '../../icons';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import {
  ChargesRates,
  Client,
  ESpecialty,
  EUserRole,
} from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

type ClientRatesProps = {
  client: Client;
  getClientDetails: () => void;
  specialty?: ESpecialty;
};

export const ChargesRatesList = ({
  client,
  getClientDetails,
  specialty,
}: ClientRatesProps) => {
  const { id } = useParams();
  const { user } = useAuth();

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const isClientBelongsToUser =
    client?.user?._id === user?._id || user?.role === EUserRole.Admin;

  const deleteRate = async (chargeRate: ChargesRates) => {
    await AxiosInstance.clients
      .clientsControllerDeleteRate(id!, chargeRate._id)
      .then(() => {
        toast.success('Rate removed successfully');
        getClientDetails();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const { openConfirm, closeConfirm } = useConfirm();
  const onDeleteConfirm = (item: ChargesRates) => {
    openConfirm({
      title: 'Delete Charge/Rate',
      component: (
        <>
          Do you want to delete{' '}
          <b data-testid="rate-to-delete">
            {item?.specialty ? `${item?.specialty}/` : ''}
            {item?.level}/{item?.grade}
          </b>{' '}
          rate ?
        </>
      ),
      onConfirm: () => deleteRate(item),
    });
  };

  const chargesRates = useMemo(() => {
    return client.chargesRates.filter((item) => {
      return specialty
        ? item.specialty === specialty
        : item.specialty === undefined;
    });
  }, [specialty]);

  return (
    <>
      <Table data-testid="rates-table">
        <thead>
          <tr>
            <th className="center-text" data-testid="job-title-header">
              <div>
                <label>Job Title</label>
              </div>
            </th>
            <th className="center-text" data-testid="grade-header">
              <div>
                <label>Grade</label>
              </div>
            </th>
            <th className="center-text" data-testid="charge-rate-header">
              <div>
                <label>Charge</label>
              </div>
            </th>

            <th className="center-text" data-testid="charge-rate-header">
              <div>
                <label>Rate</label>
              </div>
            </th>

            <th className="center-text" data-testid="charge-rate-header">
              <div>
                <label>Comission</label>
              </div>
            </th>
            <th data-testid="actions-header">
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {(chargesRates ?? [])?.map((item) => {
            return (
              <tr key={item._id} data-testid={`rate-row-${item._id}`}>
                <td
                  className="center-text"
                  data-testid={`rate-level-${item._id}`}
                >
                  {item?.level}
                </td>
                <td
                  className="center-text"
                  data-testid={`rate-grade-${item._id}`}
                >
                  {item?.grade}
                </td>
                <td
                  className="center-text"
                  data-testid={`rate-charge-${item._id}`}
                >
                  {item?.charge}
                </td>{' '}
                <td
                  className="center-text"
                  data-testid={`rate-rate-${item._id}`}
                >
                  {item?.rate}
                </td>
                <td
                  className="center-text"
                  data-testid={`rate-commission-${item._id}`}
                >
                  {item?.charge - item?.rate}
                </td>
                <td>
                  <div
                    className="action-item"
                    data-testid={`action-item-${item._id}`}
                  >
                    <div
                      className={`delete-icon ${
                        !isClientBelongsToUser && 'disabled'
                      }`}
                      onClick={() =>
                        isClientBelongsToUser && onDeleteConfirm(item)
                      }
                      data-testid={`delete-icon-${item._id}`}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
