import React from 'react';
import styled from 'styled-components';

const ComplianceStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .text {
    flex: 1;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.green-circle {
      background-color: ${(props) => props.theme.text.greenColor};
    }
    &.yellow-circle {
      background-color: ${(props) => props.theme.text.yellowColor};
    }
    &.red-circle {
      background-color: ${(props) => props.theme.text.redColor};
    }
    &.grey-circle {
      background-color: ${(props) => props.theme.text.greyColor};
    }
    &.orange-circle {
      background-color: ${(props) => props.theme.text.orangeColor};
    }
  }
`;

type ComplianceStatusProps = {
  value?: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED';
};

const getStatusValues = (
  value: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED',
) => {
  switch (value) {
    case 'IN_PROGRESS':
      return {
        className: 'yellow-circle',
        label: 'In progress',
        'data-testid': 'status-in-progress', // Added data-testid
      };
    case 'PASSED':
      return {
        className: 'green-circle',
        label: 'Passed',
        'data-testid': 'status-passed', // Added data-testid
      };
    case 'FAILED':
      return {
        className: 'red-circle',
        label: 'Failed',
        'data-testid': 'status-failed', // Added data-testid
      };
    case 'EXPIRED':
      return {
        className: 'grey-circle',
        label: 'Expired',
        'data-testid': 'status-expired', // Added data-testid
      };
    default:
      return {
        className: 'orange-circle',
        label: value,
        'data-testid': `status-${value}`, // Dynamic data-testid based on value
      };
  }
};

export const ComplianceStatus = ({
  value = 'IN_PROGRESS',
}: ComplianceStatusProps) => {
  const status = getStatusValues(value);

  return (
    <ComplianceStatusContainer data-testid="compliance-status-container">
      <div
        className={`circle ${status.className}`}
        data-testid="compliance-status-circle"
      ></div>
      <div className="text" data-testid="compliance-status-text">
        {status.label}
      </div>
    </ComplianceStatusContainer>
  );
};
