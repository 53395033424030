import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TUIType, getTypeStyles } from '../../constants';

const BadgeContainer = styled.div<{ type: TUIType }>`
  height: 28px;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  ${(props) => getTypeStyles(props.type)}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  .abbreviation-container {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  &.cursor-pointer {
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
`;

interface BadgeProps {
  children: ReactNode;
  type: TUIType;
  abbreviation?: string;
  onClick?: () => void;
  className?: string;
}

export const Badge = ({
  children,
  type,
  abbreviation,
  onClick = undefined,
  className = '',
}: BadgeProps) => {
  return (
    <BadgeContainer
      className={`badge-data text-nowrap ${onClick ? 'cursor-pointer' : ''} ${className}`}
      type={type}
      data-testid="badge-container"
      onClick={onClick}
    >
      {abbreviation && <Abbreviation>{abbreviation}</Abbreviation>}
      {children}
    </BadgeContainer>
  );
};

const AbbreviationContainer = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: #ceefdf;
  font-size: 8px;
`;

interface AbbreviationProps {
  children: ReactNode;
  title?: string;
}

export const Abbreviation = ({ children, title }: AbbreviationProps) => {
  return (
    <AbbreviationContainer
      className={`abbreviation-container`}
      title={title ?? ''}
      data-testid="abbreviation-container"
    >
      {children}
    </AbbreviationContainer>
  );
};
