import { useEffect, useMemo, useState } from 'react';
import { DonutChart, DonutChartConfig } from '../charts/donut-chart';
import { Container } from './charts.style';
import { EJobLevel, ENhsPayGrade } from '../../backend/careo-api';
import { capitalizeFirstLetter, jobLevelWithGrades } from '../../constants';
import { calculateSumBySelectionRoles } from '../../utils/statistics.utils';
import { DropdownSelectText } from '../ui';

type Props = {
  stats: Record<string, Record<string, Record<string, Record<string, number>>>>;
  selectedSpecialty?: string;
  isLoading?: boolean;
};

export const DashboardRolesChart = ({
  stats,
  selectedSpecialty,
  isLoading,
}: Props) => {
  const [isInternalLoading, setInternalIsLoading] = useState(false);
  const [chartConfig, setChartConfig] = useState<DonutChartConfig>({
    labels: [],
    backgroundColors: [
      '#8C62FF',
      '#2DD4BF',
      '#FE964A',
      '#BA55D3',
      '#468499',
      '#7CFC00',
    ],
    borderColors: [
      '#8C62FF',
      '#2DD4BF',
      '#FE964A',
      '#BA55D3',
      '#468499',
      '#7CFC00',
    ],
    size: 250,
    subtitle: 'Roles',
    title: 'Roles',
  });

  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');

  const LevelsList = jobLevelWithGrades.map((el) => ({
    label: el.level,
    value: el.level,
  }));

  const GradesList = useMemo(() => {
    return (
      jobLevelWithGrades
        .find((el) => el.level === selectedLevel)
        ?.grades?.map((el) => ({ label: el, value: el })) ?? []
    );
  }, [selectedLevel]);

  const data = useMemo(() => {
    if (!stats) return [];
    const specialties = selectedSpecialty
      ? [selectedSpecialty]
      : Object.keys(stats);

    const labels: string[] = Object.keys(stats[specialties[0]] ?? []);

    const defaultResult = Object.fromEntries(labels.map((label) => [label, 0]));

    const sumOfObjects = Object.entries(
      selectedSpecialty ? [stats[selectedSpecialty]] : stats,
    ).reduce((prev, next) => {
      Object.keys(next[1]).forEach((label) => {
        prev[label] += calculateSumBySelectionRoles(
          next[1],
          label,
          selectedLevel as EJobLevel,
          selectedGrade as ENhsPayGrade,
        );
      });
      return prev;
    }, defaultResult);

    const { data, sortedLabels } = Object.entries(sumOfObjects).reduce(
      (prev, next) => {
        prev.sortedLabels.push(next[0]);
        prev.data.push(next[1]);

        return prev;
      },
      { sortedLabels: [] as string[], data: [] as number[] },
    );

    setChartConfig({
      ...chartConfig,
      labels: sortedLabels,
    });

    return data;
  }, [stats, selectedSpecialty, selectedLevel, selectedGrade]);

  useEffect(() => {
    const loadWithDelay = async () => {
      setInternalIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setInternalIsLoading(false);
    };

    loadWithDelay();
  }, [selectedSpecialty]);

  return (
    <Container data-testid="roles-container">
      <div className="header" data-testid="roles-header">
        <span className="title" data-testid="roles-title">
          {!selectedSpecialty ? 'All' : selectedSpecialty} Roles
        </span>

        <div className="selection" data-testid="roles-selection">
          <DropdownSelectText
            items={[
              {
                label: 'All levels',
                value: '',
              },
              ...LevelsList,
            ]}
            selectedItem={selectedLevel}
            setSelectedItem={setSelectedLevel}
            data-testid="levels-dropdown"
          />
          <DropdownSelectText
            items={[
              {
                label: 'All grades',
                value: '',
              },
              ...GradesList,
            ]}
            selectedItem={selectedGrade}
            setSelectedItem={setSelectedGrade}
            disabled={!selectedLevel}
            data-testid="grades-dropdown"
          />
        </div>
      </div>
      <DonutChart
        config={chartConfig}
        data={isLoading || isInternalLoading ? [] : data}
        isLoading={isLoading || isInternalLoading}
        data-testid="donut-chart"
      />
      <div className="labels-container" data-testid="chart-data-labels">
        {chartConfig.labels.map((label, index) => (
          <div
            className="label"
            key={label}
            data-testid={`chart-label-${label}`}
          >
            <div>
              <div
                style={{
                  backgroundColor: chartConfig.backgroundColors?.[index],
                }}
                data-testid={`chart-color-box-${label}`}
              ></div>
              <div data-testid={`chart-label-text-${label}`}>
                {capitalizeFirstLetter(label)}
              </div>
            </div>
            <div data-testid={`chart-value-${label}`}>{data[index]}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};
