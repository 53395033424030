import React, { useEffect, useState } from 'react';
import { TitleCard, Button, FieldText, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Application, ValidatePlacementDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  PlacementValidateSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type NewPlacementFormProps = {
  application: Application;
  onCancel: () => void;
  getPlacementDetails: any;
};

export const ValidatePlacementForm = ({
  application,
  onCancel,
  getPlacementDetails,
}: NewPlacementFormProps) => {
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<ValidatePlacementDto>({
    resolver: yupResolver(PlacementValidateSchema),
  });

  const onClickClose = () => {
    onCancel();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerValidatePlacement(application._id, {
        fee: Number(values.fee),
        onCallFee: Number(values.onCallFee),
        onCallRate: Number(values.onCallRate),
        vat: Number(values.vat),
      })
      .then(() => {
        getPlacementDetails();
        toast.success('Placement validated successfully');
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({ fee: application.fee });
  }, []);

  return (
    <FormContainer>
      <TitleCard data-testid="placement-charges-title">
        Placement Charges
      </TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Charge (per hour)"
        required
        register={register('fee', { required: true })}
        type="currency"
        min={0}
        error={errors.fee}
        data-testid="charge-per-hour"
      />
      <FieldText
        placeholder="Enter here ..."
        label="VAT"
        required
        register={register('vat', { required: true })}
        type="currency"
        min={0}
        error={errors.vat}
        data-testid="vat"
      />
      <FieldText
        placeholder="Enter here ..."
        label="On Call Rate (per hour)"
        required
        register={register('onCallRate', { required: true })}
        type="currency"
        min={0}
        error={errors.onCallRate}
        data-testid="on-call-rate"
      />
      <FieldText
        placeholder="Enter here ..."
        label="On Call Charge (per hour)"
        required
        register={register('onCallFee', { required: true })}
        type="currency"
        min={0}
        error={errors.onCallFee}
        data-testid="on-call-fee"
      />
      <div className="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="validate-button"
        >
          Validate
        </Button>
      </div>
    </FormContainer>
  );
};
