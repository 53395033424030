import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';

export const RotasContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  .weeksList-wrapper {
    border-radius: 4px;
    border: 1px solid #e5e8eb;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0 0.5rem;
    gap: 16px;
    padding: 4px 8px;
    flex: 1;
    max-width: 100%;
    overflow: hidden;

    .weeksList-list-container {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
    }
    .week-divider {
      border-radius: 1px;
      background: #e5e8eb;
      width: 1px;
      height: 16px;
    }
    .week-button {
      width: 104px;
      padding: 0px 10px;
      border-radius: 4px;
      font-size: 12px;
      background-color: unset !important;
      border: unset;
      outline: unset;
      flex: 0 0 auto;
      margin: 0 0.25rem;
      border-radius: 4px;
      color: #333;
      cursor: pointer;
      font-weight: bold;
      &.active {
        background-color: #0caf60 !important;
        color: white !important;
        border-color: #4caf50;
      }
    }
  }

  .scroll-arrow {
    cursor: pointer;
    color: #888;
    width: 30px;
    height: 30px;
    border: 1px solid #e5e8eb;
    background-color: #fbfbfb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #6c6c6c;
    }
  }
`;

// Type guard to check if the event is a TouchEvent
const isTouchEvent = (
  e: React.MouseEvent | React.TouchEvent,
): e is React.TouchEvent => 'touches' in e;

export type WeekData = {
  startDate: Date;
  endDate: Date;
  label: string;
  week: number;
  month: number;
  year: number;
};

type WeeksSliderProps = {
  weeksList: WeekData[];
  selectedWeek: WeekData | undefined;
  setSelectedWeek: Dispatch<SetStateAction<WeekData | undefined>>;
  setSelectedMonth: Dispatch<SetStateAction<number>>;
  setSelectedYear: Dispatch<SetStateAction<number>>;
};

export const WeeksSlider = ({
  weeksList,
  selectedWeek,
  setSelectedWeek,
  setSelectedMonth,
  setSelectedYear,
}: WeeksSliderProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const updateScrollButtonState = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsScrollLeftDisabled(scrollLeft <= 0);
      setIsScrollRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const scrollLeftArrow = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
      updateScrollButtonState();
    }
  };

  const scrollRightArrow = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
      updateScrollButtonState();
    }
  };

  const onSelectWeek = (index: number) => {
    setSelectedWeek(weeksList[index]);
    setSelectedMonth(weeksList[index].month);
    setSelectedYear(weeksList[index].year);
    scrollToCurrentWeek(index);
  };

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    const pageX = isTouchEvent(e) ? e.touches[0].pageX : e.pageX;
    setStartX(pageX);
    setScrollLeft(containerRef.current?.scrollLeft ?? 0);
  };

  const handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging || !containerRef.current) return;
    const pageX = isTouchEvent(e) ? e.touches[0].pageX : e.pageX;
    const walk = (pageX - startX) * 1.5;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    updateScrollButtonState();
  };

  const scrollToCurrentWeek = (selectedWeekNumber: number) => {
    if (containerRef.current && selectedWeekNumber === 0) {
      containerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    } else if (buttonRefs.current[selectedWeekNumber]) {
      buttonRefs.current[selectedWeekNumber].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    scrollToCurrentWeek(selectedWeek?.week ?? 0);
  }, [selectedWeek?.week]);

  return (
    <RotasContainer data-testid="rotas-container">
      <div
        className={`scroll-arrow ${isScrollLeftDisabled ? 'disabled' : ''}`}
        onClick={!isScrollLeftDisabled ? scrollLeftArrow : undefined}
        data-testid="scroll-left-arrow"
      >
        <LeftArrowIcon data-testid="left-arrow-icon" />
      </div>
      <div className="weeksList-wrapper" data-testid="weeksList-wrapper">
        <div
          className="weeksList-list-container"
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          data-testid="weeksList-list-container"
        >
          {weeksList.map((week, index) => (
            <React.Fragment key={week.startDate.toJSON()}>
              {index > 0 && (
                <div
                  className="week-divider"
                  data-testid={`week-divider-${index}`}
                >
                  &nbsp;
                </div>
              )}
              <button
                ref={(el) => (buttonRefs.current[index] = el!)}
                className={`week-button ${index + 1 === selectedWeek?.week ? 'active' : ''}`}
                onClick={() => onSelectWeek(index)}
                data-testid={`week-button-${index}`}
              >
                {week.label}
              </button>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div
        className={`scroll-arrow ${isScrollRightDisabled ? 'disabled' : ''}`}
        onClick={!isScrollRightDisabled ? scrollRightArrow : undefined}
        data-testid="scroll-right-arrow"
      >
        <RightArrowIcon data-testid="right-arrow-icon" />
      </div>
    </RotasContainer>
  );
};
