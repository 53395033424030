import * as yup from 'yup';
import { regex } from '../regex.utils';
import {
  ELeadDurationType,
  ELeadStatus,
  ELeadStrength,
  ELeadTag,
  ELeadType,
  EMonth,
  ESpecialty,
} from '../../backend/careo-api';

const generalLeadSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(regex.name, 'Name is not valid'),

  status: yup
    .string()
    .oneOf(Object.values(ELeadStatus), 'Invalid status')
    .required('Status is required'),

  specialty: yup
    .string()
    .oneOf(Object.values(ESpecialty), 'Invalid specialty')
    .required('Specialty is required'),

  tag: yup
    .string()
    .oneOf(Object.values(ELeadTag), 'Invalid tag')
    .required('Tag is required'),

  strength: yup
    .string()
    .oneOf(Object.values(ELeadStrength), 'Invalid strength')
    .required('Strength is required'),

  type: yup
    .string()
    .oneOf(Object.values(ELeadType), 'Invalid type')
    .required('Type is required'),

  cpmIds: yup
    .array()
    .of(yup.string().required('Each CPM ID must be a string'))
    .required('CPM IDs are required'),

  region: yup
    .string()
    .required('Region is required')
    .matches(regex.name, 'Region is not valid'),

  trustId: yup.string(),

  hospitalId: yup.string().required('Hospital ID is required'),

  overview: yup.string(),

  resourceRequirement: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .required('Resource requirement is required'),

  startingMonth: yup
    .number()
    .oneOf(
      Object.values(EMonth).filter(
        (val) => typeof val === 'number',
      ) as number[],
      'Invalid starting month',
    )
    .required('Starting month is required'),

  durationValue: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value,
    )
    .positive('Duration value must be a positive number')
    .required('Duration value is required'),

  durationType: yup
    .string()
    .oneOf(Object.values(ELeadDurationType), 'Invalid duration type')
    .required('Duration type is required'),
});

const financialLeadSchema = yup.object().shape({
  revenue: yup
    .number()
    .required('Revenue is required')
    .typeError('Revenue must be a number'),

  cost: yup
    .number()
    .required('Cost is required')
    .typeError('Cost must be a number'),

  margin: yup
    .number()
    .required('Margin is required')
    .typeError('Margin must be a number'),

  benchmark: yup
    .number()
    .required('Benchmark is required')
    .typeError('Benchmark must be a number'),
});

const legalLeadSchema = yup.object().shape({
  legalContactId: yup.string().required('Legal contact is required'),
});

export { generalLeadSchema, financialLeadSchema, legalLeadSchema };
