import { PlacementsListPage, PlacementDetailsPage } from '../pages/placement';
import { EPlacementDetailsTabs } from '../pages/placement/placement-details/placement-details.page';

import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';

export const PlacementsRoutes: IRoute[] = [
  {
    path: `/${ERoute.PLACEMENTS}`,
    element: <PlacementsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.PLACEMENTS}/:id`,
    element: <PlacementDetailsPage />,
  },
  {
    path: `/${ERoute.PLACEMENTS}/:id/${EPlacementDetailsTabs.GENERAL}`,
    element: <PlacementDetailsPage />,
  },
  {
    path: `/${ERoute.PLACEMENTS}/:id/${EPlacementDetailsTabs.TIMESHEETS}`,
    element: <PlacementDetailsPage />,
  },
  {
    path: `/${ERoute.PLACEMENTS}/:id/${EPlacementDetailsTabs.INVOICES}`,
    element: <PlacementDetailsPage />,
  },
  {
    path: `/${ERoute.PLACEMENTS}/:id/${EPlacementDetailsTabs.DOCUMENTS}`,
    element: <PlacementDetailsPage />,
  },
];
