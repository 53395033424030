import React, { ChangeEvent, useEffect, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { FieldTextContainer } from './field-text.style';

type TextareaProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  register?: any;
  className?: string;
  min?: number;
  max?: number;
  rows?: number;
  disabled?: boolean;
  onKeyDown?: any;
  error?: FieldError;
};

export const Textarea = ({
  label,
  placeholder = '',
  required,
  onChange,
  value,
  register,
  className,
  max,
  min,
  disabled,
  rows = 3,
  error,
  ...props
}: TextareaProps) => {
  const textAreaRef = useRef<any>(null);
  const maxHeight = 85;

  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef.current.style.height = 'auto';
      const scrollHeight =
        textAreaRef.current.scrollHeight > maxHeight
          ? maxHeight
          : textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);

  return (
    <FieldTextContainer
      className={className}
      hasError={!!error}
      data-testid="field-text-container"
    >
      {label && (
        <label htmlFor="" data-testid="field-text-label">
          {label} {required && <span>*</span>}
        </label>
      )}

      <div className="input-container" data-testid="input-container">
        <textarea
          {...props}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          min={min}
          max={max}
          disabled={disabled}
          ref={textAreaRef}
          rows={rows}
          className="form-control"
          data-testid="field-textarea"
          {...register}
        />
        {error && (
          <span className="error-container" data-testid="field-text-error">
            {error?.message}
          </span>
        )}
      </div>
    </FieldTextContainer>
  );
};
