import React, { useState } from 'react';
import { MainContentContainer } from './app-layout.style';
import { Header } from '../header/header.component';
import { Sidebar } from '../sidebar/sidebar.component';
import { Outlet } from 'react-router-dom';
import { PORTALS } from '../../../backend/careo-api';
import { ModalProvider } from '../../../contexts/side-modal.context';
import { ConfirmProvider } from '../../../contexts/confirm-modal.context';

export const AppLayout = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  return (
    <>
      <ConfirmProvider>
        <ModalProvider>
          {process.env.REACT_APP_NAME === PORTALS.Crm && (
            <Sidebar
              isSidebarActive={isSidebarActive}
              setIsSidebarActive={setIsSidebarActive}
              data-testid="sidebar"
            />
          )}

          <MainContentContainer
            isSidebarActive={isSidebarActive}
            data-testid="main-content-container"
          >
            <Header
              isSidebarActive={isSidebarActive}
              setIsSidebarActive={setIsSidebarActive}
              data-testid="header"
            />
            <div className="divider" data-testid="divider"></div>
            <div className="content" data-testid="content">
              <Outlet data-testid="outlet" />
            </div>
          </MainContentContainer>
        </ModalProvider>
      </ConfirmProvider>
    </>
  );
};
