import { LeadsListPage } from '../pages/lead/leads-list.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';

export const LeadsRoutes: IRoute[] = [
  {
    path: `/${ERoute.LEADS}`,
    element: <LeadsListPage />,
  },
];
