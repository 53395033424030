import React, { useState } from 'react';
import { Button, FieldText } from '../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  RenameDocumentValidator,
} from '../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { handleFormsServerErrors } from '../../utils';
import { HostedFile } from '../../backend/careo-api';

type NewRenameDocumentFormProps = {
  document: HostedFile;
  onCancel: () => void;
  onSuccess: (_id: string, newFileName: string) => void;
};

export const RenameDocumentForm = ({
  document,
  onSuccess,
  onCancel,
}: NewRenameDocumentFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<{ newName: string }>({
    resolver: yupResolver(RenameDocumentValidator),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    const extension = document.fileName.split('.')[1];

    const newFileName = `${values.newName}${extension ? '.' + extension : ''}`;

    AxiosInstance.files
      .filesControllerRenameCandidateFile(document._id, newFileName)
      .then(() => {
        toast.success('Document renamed successfully');
        onSuccess(document._id, `${values.newName}`);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <FieldText
        placeholder="Enter here ..."
        label={`Old Name (${document.fileName?.split?.('.')?.[1]})`}
        required
        value={document.fileName?.split?.('.')?.[0] ?? ''}
        disabled
        data-testid="old-file-name"
      />
      <FieldText
        placeholder="Enter here ..."
        label={`New Name (${document.fileName?.split?.('.')?.[1]})`}
        required
        register={register('newName', { required: true })}
        error={errors.newName}
        data-testid="new-file-name"
      />
      <div className="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="rename-button"
        >
          Rename
        </Button>
      </div>
    </>
  );
};
