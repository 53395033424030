import React, { useEffect, useState } from 'react';
import { VacancyApplicationsListContainer } from './vacancy-application.style';
import {
  AddCandidateVacancy,
  Button,
  CardContainer,
  SearchInput,
  SubTitlePage,
  TitlePage,
  HeaderPageContainer,
  UpdateApplicationForm,
  ApplicationCardPipeline,
  DraggableArea,
  PipelineContainer,
  PipelineStatus,
} from '../../../components';
import { PlusIcon } from '../../../icons';
import {
  Application,
  Candidate,
  Job,
  UpdateApplicationStatusDto,
} from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { applicationStatusList } from '../../../constants';
import { rejectedPlacementStatuses } from '../../placement/placements-list/placements-list.page';
import { useModal } from '../../../contexts/side-modal.context';

export const VacancyApplicationsListPage = () => {
  const { id } = useParams();
  const { openModal, closeModal } = useModal();

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [vacancy, setVacancy] = useState<Job | null>(null);

  const [pipelines, setPipelines] = useState<PipelineStatus<Application>[]>(
    applicationStatusList.map((el) => ({ ...el, items: [] })),
  );

  const getApplications = () => {
    AxiosInstance.applications
      .applicationsControllerFindAll({ jobId: id })
      .then((response) => {
        const applications = response.data.items.filter(
          (el) => !rejectedPlacementStatuses.includes(el.approvalStatus!),
        );
        const groupedApplications = applications.reduce((acc: any, curr) => {
          const status = curr.status; // Assuming status is a property of the application model
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(curr);
          return acc;
        }, {});

        setPipelines((prev) => {
          prev = prev.map((el) => ({
            ...el,
            items: groupedApplications[el.value] ?? [],
          }));

          return prev;
        });
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickCreate = () => {
    openModal({
      title: 'Add Candidate to vacancy',
      component: (
        <AddCandidateVacancy
          candidates={candidates}
          job={vacancy!}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getApplications();
            closeModal();
          }}
          data-testid="add-candidate-vacancy"
        />
      ),
    });
  };

  const onClickEdit = (application: Application) => {
    openModal({
      title: 'Update Application',
      component: (
        <UpdateApplicationForm
          selectedApplication={application}
          onSuccess={() => {
            getApplications();
            closeModal();
          }}
          data-testid="update-application-form"
          onCancel={() => closeModal()}
        />
      ),
    });
  };

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setVacancy(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDeleteApplication = async (application: Application) => {
    await AxiosInstance.applications
      .applicationsControllerDeleteApplication(application._id)
      .then(() => {
        getApplications();
        toast.success('Application Removed successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = applicationStatusList[newListIndex].value;

    await AxiosInstance.applications.applicationsControllerUpdateApplicationStatus(
      id,
      {
        status: newStatus,
      } as UpdateApplicationStatusDto,
    );
  };

  useEffect(() => {
    getVacancyDetails();
    getApplications();
    getCandidates();
  }, []);

  if (!vacancy) {
    return <></>;
  }

  return (
    <>
      <VacancyApplicationsListContainer data-testid="vacancy-applications-list-container">
        <CardContainer
          className="vacancies-list-content"
          data-testid="vacancies-list-content"
        >
          <HeaderPageContainer data-testid="header-page-container">
            <div className="left-container" data-testid="left-container">
              <TitlePage data-testid="vacancies-title">Vacancies</TitlePage>
              <SubTitlePage data-testid="vacancies-subtitle">
                List Job - {vacancy.grade}/{vacancy.specialty}
              </SubTitlePage>
            </div>
            <div className="right-container" data-testid="right-container">
              <SearchInput
                placeholder="Search what you need"
                disabled
                data-testid="search-input"
              />
              <Button
                type="primary"
                onClick={() => onClickCreate()}
                data-testid="add-candidates-button"
              >
                <PlusIcon data-testid="plus-icon" /> Add Candidates
              </Button>
            </div>
          </HeaderPageContainer>
          <PipelineContainer data-testid="pipeline-container">
            {pipelines.map((el, listIndex: number) => (
              <div
                className="item-container"
                key={listIndex}
                data-testid={`item-container-${listIndex}`}
              >
                <div
                  className="item-header"
                  data-testid={`item-header-${listIndex}`}
                >
                  <div
                    className="item-title"
                    data-testid={`item-title-${listIndex}`}
                  >
                    <label>{el.label}</label>
                    <div
                      className="item-total"
                      data-testid={`item-total-${listIndex}`}
                    >
                      {el.items.length}
                    </div>
                  </div>
                  <PlusIcon data-testid={`plus-icon-${listIndex}`} />
                </div>
                <div
                  className="cards-list-container"
                  data-testid={`cards-list-container-${listIndex}`}
                >
                  {el.isDraggableTo ? (
                    <DraggableArea
                      pipeline={pipelines}
                      listIndex={listIndex}
                      itemIndex={0}
                      onSuccess={getApplications}
                      onDragRequest={handleDrop}
                      data-testid={`draggable-area-${listIndex}`}
                    />
                  ) : (
                    <div
                      className="draggable-area false"
                      data-testid={`draggable-area-false-${listIndex}`}
                    >
                      <hr />
                    </div>
                  )}
                  {el.items
                    .sort(
                      (a, b) =>
                        new Date(a.availableFrom).getTime() -
                        new Date(b.availableFrom).getTime(),
                    )
                    .map((item, itemIndex: number) => (
                      <React.Fragment key={item._id}>
                        <ApplicationCardPipeline
                          item={item}
                          listIndex={listIndex}
                          itemIndex={itemIndex}
                          onClickDeleteApplication={onClickDeleteApplication}
                          onClickEdit={onClickEdit}
                          data-testid={`application-card-pipeline-${item._id}`}
                        />
                        {el.isDraggableTo ? (
                          <DraggableArea
                            pipeline={pipelines}
                            listIndex={listIndex}
                            itemIndex={0}
                            onSuccess={getApplications}
                            onDragRequest={handleDrop}
                            data-testid={`draggable-area-2-${listIndex}`}
                          />
                        ) : (
                          <div
                            className="draggable-area false"
                            data-testid={`draggable-area-false-2-${listIndex}`}
                          >
                            <hr />
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ))}
          </PipelineContainer>
        </CardContainer>
      </VacancyApplicationsListContainer>
    </>
  );
};
