import React, { useState } from 'react';
import { Project } from '../../backend/careo-api';

import { ColumnSwitch, RowSwitch } from '../../icons';
import { MultipleSelect, SearchInput, TitleCard } from '../ui';
import { ProjectMatchingPipeline } from './project-matching-pipeline.component';
import { ProjectMatchingList } from './project-matching-list.component';

type ProjectDetailsMatchingProps = {
  project: Project;
};

export const ProjectDetailsMatching = ({
  project,
}: ProjectDetailsMatchingProps) => {
  const [isRowSwitch, setIsRowSwitch] = useState(false);

  return (
    <div className="matching-list-content" data-testid="matching-list-content">
      <div className="filter-container" data-testid="filter-container">
        <TitleCard className="title-page" data-testid="role-matching-title">
          Role Matching
        </TitleCard>
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={(e) => {}}
          data-testid="status-select"
        />
        <MultipleSelect
          placeholder="All Tags"
          options={[]}
          onChange={(e) => {}}
          data-testid="tags-select"
        />
        <SearchInput
          placeholder="Search candidates"
          onChange={(e) => {}}
          data-testid="search-input"
        />
        {isRowSwitch ? (
          <RowSwitch
            onClick={() => setIsRowSwitch(false)}
            data-testid="row-switch"
          />
        ) : (
          <ColumnSwitch
            onClick={() => setIsRowSwitch(true)}
            data-testid="column-switch"
          />
        )}
      </div>
      {isRowSwitch ? (
        <ProjectMatchingList data-testid="project-matching-list" />
      ) : (
        <ProjectMatchingPipeline data-testid="project-matching-pipeline" />
      )}
    </div>
  );
};
