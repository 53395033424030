import React from 'react';
import { Badge } from '../ui';
import { EApplicationApprovalStatus } from '../../backend/careo-api';
import { applicationApprovalStatusList, TUIType } from '../../constants';

export const PlacementStatusBadge = (placement: {
  approvalStatus: EApplicationApprovalStatus;
}) => {
  const defaultBadge = { label: 'Unknown Status', type: 'default' as TUIType };

  const badge =
    applicationApprovalStatusList.find(
      (el) => el.value === placement.approvalStatus,
    ) || defaultBadge;

  return (
    <Badge type={badge.type} data-testid="badge">
      {badge.label}
    </Badge>
  );
};
