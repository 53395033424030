import React, { useState } from 'react';
import { TitleCard, Button, Textarea, FormContainer } from '../ui';
import { Candidate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';

type AddNewNoteCandidateFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidate: Candidate;
};

export const AddNewNoteCandidateForm = ({
  onCancel,
  onSuccess,
  candidate,
}: AddNewNoteCandidateFormProps) => {
  const [newNote, setNewNote] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    AxiosInstance.candidates
      .candidatesControllerCreateNoteCandidate(candidate._id, {
        text: newNote,
      })
      .then(() => {
        toast.success('Candidate Note updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setIsSubmitting(false);
  };

  return (
    <FormContainer data-testid="form-container">
      <TitleCard data-testid="title-card">Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
        data-testid="note-textarea"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => onCancel()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onClickSubmit}
          data-testid="submit-button"
          disabled={isSubmitting}
        >
          Add Note
        </Button>
      </div>
    </FormContainer>
  );
};
