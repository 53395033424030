import React from 'react';
import { MessageListContainer } from './messages-list.style';
import { UserIcon } from '../../ui';
import { ChatListItemDto, MEMBER_TYPE } from '../../../backend/careo-api';
import { getTimeSince } from '../../../utils';

type MessageListProps = {
  memberType: MEMBER_TYPE;
  data: ChatListItemDto[];
  onMemberSelected: (userId: string) => void;
  selectedMemberId?: string;
  isLoading?: boolean;
};

export const MessageList = ({
  memberType,
  data,
  onMemberSelected,
  selectedMemberId,
  isLoading = false,
}: MessageListProps) => {
  return (
    <MessageListContainer className="messages-list" data-testid="messages-list">
      {isLoading ? (
        <div className="no-items" data-testid="loading-message">
          Loading ...
        </div>
      ) : (
        <>
          <div className="bottom-container inbox-list" data-testid="inbox-list">
            {data.length ? (
              <>
                {data.map((el, index) => (
                  <div
                    className={`inbox-item ${
                      el.member._id === selectedMemberId && 'active'
                    }`}
                    key={index}
                    onClick={() => onMemberSelected(el.member._id)}
                    data-testid={`inbox-item-${el.member._id}`}
                  >
                    <div
                      className="profile-image"
                      data-testid={`profile-image-${el.member._id}`}
                    >
                      <UserIcon
                        firstName={el.member.firstName}
                        lastName={el.member.lastName}
                        entity={memberType}
                        data-testid={`user-icon-${el.member._id}`}
                      />
                    </div>
                    <div
                      className="inbox-container"
                      data-testid={`inbox-container-${el.member._id}`}
                    >
                      <div
                        className="inbox-header"
                        data-testid={`inbox-header-${el.member._id}`}
                      >
                        <div
                          className="inbox-name"
                          data-testid={`inbox-name-${el.member._id}`}
                        >
                          {el.member.firstName} {el.member.lastName}
                        </div>
                        <div
                          className="inbox-time"
                          data-testid={`inbox-time-${el.member._id}`}
                        >
                          {el.lastMessageTimestamp
                            ? getTimeSince(el.lastMessageTimestamp)
                            : ''}
                        </div>
                      </div>
                      <div
                        className="inbox-content"
                        data-testid={`inbox-content-${el.member._id}`}
                      >
                        <div
                          className="last-message"
                          data-testid={`last-message-${el.member._id}`}
                        >
                          {el.lastMessage || 'click to open conversation'}
                        </div>
                        {Number(el?.unreadMessagesCount) > 0 && (
                          <div
                            className="number-unread-messages"
                            data-testid={`unread-messages-${el.member._id}`}
                          >
                            {el.unreadMessagesCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="no-items" data-testid="no-results">
                No results
              </div>
            )}
          </div>
        </>
      )}
    </MessageListContainer>
  );
};
