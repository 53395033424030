import React from 'react';
import { Button } from '../ui';
import { Client, EUserRole } from '../../backend/careo-api';
import { useAuth } from '../../contexts/auth.context';
import { ChargesRatesList } from './charges-rates-list.components';
import { useModal } from '../../contexts/side-modal.context';
import { NewRateForm } from './new-rate-form.component';

type ClientRatesProps = {
  client: Client;
  getClientDetails: () => void;
};

export const ChargesRatesTab = ({
  client,
  getClientDetails,
}: ClientRatesProps) => {
  const { user } = useAuth();

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Charge/Rate',
      component: (
        <NewRateForm
          getClientDetails={getClientDetails}
          onCancel={() => closeModal()}
          data-testid="new-rate-form"
        />
      ),
    });
  };

  const isClientBelongsToUser =
    client?.user?._id === user?._id || user?.role === EUserRole.Admin;
  return (
    <>
      <div className="info-card" data-testid="info-card">
        <div className="info-card-header" data-testid="info-card-header">
          <div className="info-card-title" data-testid="info-card-title">
            Charges Rates List
          </div>
          {isClientBelongsToUser && (
            <Button
              type="primary"
              onClick={onClickCreate}
              data-testid="add-rate-button"
            >
              Add new rate
            </Button>
          )}
        </div>
        <hr />
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <ChargesRatesList
            client={client}
            getClientDetails={getClientDetails}
          />
        </div>
      </div>
    </>
  );
};
