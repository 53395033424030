import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file-list.component';
import { DocumentsListComponent } from '../../document/documents-list.component';
import { useModal } from '../../../contexts/side-modal.context';

type RightWorkProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'passportFiles';

export const RightWork = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: RightWorkProps) => {
  const { id } = useParams();
  const [isValid, setIsValid] = useState<boolean>();

  const { openModal, closeModal } = useModal();

  const onClickEdit = (fileKey: TFilesKey) => {
    openModal({
      title: 'Select File',
      component: (
        <SelectFileList
          fileKey={fileKey}
          onCancel={() => closeModal()}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list"
        />
      ),
    });
  };

  const { register, getValues, setValue } = useForm<{
    passport: string;
    note: string;
    passportFiles: any[];
  }>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([{ key: 'passportFiles', files: [] }]);

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'passportFiles' | 'passport' | 'note',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      setValue('passport', compliance?.metadata?.passport ?? '3');
      setValue('note', compliance?.metadata?.note ?? '3');
      const passportFiles = compliance?.metadata?.passportFiles ?? [];
      setValue('passportFiles', passportFiles);
      setFilesList([{ key: 'passportFiles', files: passportFiles }]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      {/* Passport Identification Section */}
      <TitleCard data-testid="title-card-passport">
        Passport Identification
      </TitleCard>
      <RadioGroup
        name="passport"
        options={[
          { label: 'British Passport (incl N.Ireland)', value: '1' },
          { label: 'European Union', value: '2' },
          { label: 'Rest of world ', value: '3' },
        ]}
        register={register('passport')}
        onChange={(value) => {
          onUpdateCompliance('passport', value);
        }}
        data-testid="passport-radio-group"
      />
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-passport"
      >
        <div className="col-md-6" data-testid="col-passport">
          <FieldFile
            label="Upload Passport"
            onChange={(e) => handleFileChange(e, 'passportFiles')}
            data-testid="field-file-passport"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => onClickEdit('passportFiles')}
          data-testid="select-passport-btn"
        >
          <Button type="primary" data-testid="select-passport-btn-label">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'passportFiles')?.files ?? []
        }
        onClickDelete={(id) => {
          onClickDelete('passportFiles', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid="documents-list-passport"
      />

      {/* Administer validity checks for YOTI or Trust ID */}
      <TitleCard data-testid="title-card-trust-id">
        Administer validity checks for YOTI or Trust ID
      </TitleCard>
      <div
        className="description"
        data-testid="validation-approved-description"
      >
        Validation approved
      </div>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="trust"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        data-testid="trust-id-radio-group"
      />

      {/* Notes Section */}
      <TitleCard data-testid="title-card-notes">Notes</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'British Passport (incl N.Ireland)',
            value: 1,
            description: 'No further action - proceed candidate application',
          },
          {
            label: 'European Union',
            value: 2,
            description: (
              <>
                Full EU settlement or Pre settlement status.
                <br />
                <br />
                This is confirmed via a sharecode - provided by candidate
                Sharecode is verified via government gateway.
              </>
            ),
          },
          {
            label: 'Rest of the World',
            value: 0,
            description: (
              <>
                Candidate must demonstrate "right to work status". Qualification
                = - Limited leave to remain. - Indefinite leave to remain. -
                Spouse visa. Student visa or tier 1-5 <br />
                <br />
                <strong>
                  **UK Home office rules are consistently subject to change**
                </strong>
              </>
            ),
          },
        ]}
        name="note"
        register={register('note')}
        onChange={(value) => {
          onUpdateCompliance('note', value);
        }}
        data-testid="notes-radio-group"
      />
    </>
  );
};
