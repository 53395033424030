import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';
import { toast } from 'react-toastify';
import { AxiosInstanceErrorResponse } from '../../../utils';

export type PipelineStatus<T> = {
  label: string;
  value: string;
  isDraggableTo?: boolean;
  items: ({ _id: string } & T)[];
};

type DraggableAreaProps = {
  onDragRequest: (id: string, newListIndex: number) => Promise<void>;
  pipeline: { label: string; value: string; items: { _id: string }[] }[];
  onSuccess: () => void;
  listIndex: number;
  itemIndex: number;
};

export const DraggableArea = ({
  onDragRequest,
  pipeline,
  onSuccess,
  listIndex,
  itemIndex,
}: DraggableAreaProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsActive(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const draggedListIndex = e.dataTransfer.getData(
      'listIndex',
    ) as unknown as number;
    const draggedItemIndex = e.dataTransfer.getData(
      'itemIndex',
    ) as unknown as number;

    const newPipeline = [...pipeline];
    const draggedItem = newPipeline[draggedListIndex].items[draggedItemIndex];

    onDragRequest(draggedItem._id, listIndex)
      .then(() => {
        newPipeline[draggedListIndex].items.splice(draggedItemIndex, 1);
        newPipeline[listIndex].items.splice(itemIndex, 0, draggedItem);
        onSuccess();
        setIsActive(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsActive(false);
      });
  };

  return (
    <div
      className={`draggable-area ${isActive && 'isActive'}`}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e)}
      onDragLeave={handleDragLeave}
      data-testid="draggable-area"
    >
      <hr data-testid="horizontal-line" />
    </div>
  );
};

export const handleDragStart = (
  e: any,
  listIndex: number,
  itemIndex: number,
) => {
  e.dataTransfer.setData('listIndex', listIndex);
  e.dataTransfer.setData('itemIndex', itemIndex);
};

export const PipelineContainer = styled.div`
  display: flex;
  gap: 24px;
  overflow-x: auto;
  flex: 1;
  min-height: 40vh;
  .item-container {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 15px;
    min-width: 294px;
    max-width: 294px;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-title {
        display: flex;
        gap: 10px;
        label {
          font-size: 14px;
        }
        .item-total {
          font-size: 12px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          border: 1px solid #27a376;
          color: #27a376;
        }
      }
      svg {
        path {
          fill: ${(props) => props.theme.text.blackColor};
        }
      }
    }
    .cards-list-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      hr {
        visibility: hidden;
      }
      .draggable-area {
        &:last-child {
          flex: 1;
        }
        hr {
          visibility: hidden;
        }
        &.isActive {
          hr {
            visibility: visible;
            border: 5px solid ${(props) => props.theme.text.greenColor};
            border-radius: 10px;
            transition: background-color 0.5s ease;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 10px;
    .item-container {
      padding: 10px;
      min-width: 170px;
      max-width: 170px;
      .item-header {
        .item-title {
          gap: 5px;
          label {
            font-size: 10px;
          }
          .item-total {
            font-size: 8px;
            width: 15px;
            height: 15px;
          }
        }
      }
      .cards-list-container {
        .draggable-area {
        }
      }
    }
  }
`;
