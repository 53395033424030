import { IRoute } from '../types/routes.type';
import { DashboardPage } from '../pages/dashboard';
import { ERoute } from '../utils';

export const DashboardRoutesCRM: IRoute[] = [
  {
    path: `/${ERoute.DASHBOARD}`,
    element: <DashboardPage />,
    isVisibleOnSidebar: false,
  },
];
