import React, { useEffect, useState } from 'react';
import {
  Button,
  CardContainer,
  FieldText,
  HeaderPageContainer,
  MultipleSelect,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
} from '../../../components';
import { PlusIcon } from '../../../icons';
import { daysTitleList, monthsList } from '../../../constants';
import { getCalendarByMonthAndYear } from '../../../utils';

export const CalendarPage = () => {
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [weeksDay, setWeeksDays] = useState<
    {
      day: number;
      label: string;
      week: number;
      month: number;
      year: number;
    }[][]
  >([]);

  useEffect(() => {
    if (selectedYear && selectedMonth >= 0) {
      setWeeksDays(getCalendarByMonthAndYear(selectedMonth, selectedYear));
    }
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-container">
          <div className="left-container" data-testid="header-left-container">
            <TitlePage data-testid="calendar-title">Calendar</TitlePage>
            <SubTitlePage data-testid="calendar-subtitle">
              Manage your Calendar
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="header-right-container">
            <Button
              type="primary"
              onClick={() => {}}
              data-testid="add-new-button"
            >
              <PlusIcon data-testid="plus-icon" /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div
          className="filter-container filter-container-calendar"
          data-testid="filter-container"
        >
          <Select
            placeholder="Select date"
            options={monthsList}
            className="month-input"
            value={selectedMonth}
            onChange={(value) => setSelectedMonth(Number(value))}
            data-testid="select-month"
          />
          <FieldText
            className="month-input"
            type="number"
            placeholder="Select Year"
            min={2000}
            onChange={(e) => setSelectedYear(Number(e.target.value) ?? 2000)}
            value={selectedYear}
            data-testid="select-year"
          />
          <Select
            placeholder="Select Week"
            options={weeksDay.map((el, index) => ({
              label: `Week ${index + 1}`,
              value: index,
            }))}
            className="month-input"
            value={selectedWeek}
            onChange={(value) => setSelectedWeek(Number(value))}
            data-testid="select-week"
          />
          <MultipleSelect
            placeholder="All Offices"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="select-offices"
          />
          <MultipleSelect
            placeholder="All Job Titles"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="select-job-titles"
          />
          <MultipleSelect
            placeholder="All Status"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="select-status"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table className="calendar" data-testid="calendar-table">
            <thead>
              <tr>
                {daysTitleList.map((el) => (
                  <th key={el.value} data-testid={`day-title-${el.value}`}>
                    {el.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {(weeksDay[selectedWeek] ?? []).map((el) => (
                  <td
                    key={el.day}
                    className={el.label !== 'currentMonth' ? 'disabled' : ''}
                    data-testid={`calendar-day-${el.day}`}
                  >
                    <div>{el.day}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </CardContainer>
    </>
  );
};
