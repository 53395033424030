import React, { useState } from 'react';
import { formatCurrency, formatDate, TSortValues } from '../../utils';
import { Abbreviation, Badge, Button, Table } from '../ui';
import { EditIcon, EyeIcon, PlusIcon } from '../../icons';
import { Project } from '../../backend/careo-api';
import { Accordion } from '../ui/accordion';
import { InfoCardRow } from '../ui/filters/tab-form.component';

type ProjectDetailsActivitiesProps = {
  project: Project;
};

type SortState = {
  key: string;
  value: TSortValues;
};

export const ProjectDetailsActivities = ({
  project,
}: ProjectDetailsActivitiesProps) => {
  const [sort, setSort] = useState<SortState>({ key: '', value: '' });

  return (
    <div
      className="info-card accordion"
      id="activities-accordion"
      data-testid="activities-accordion"
    >
      {project.activities.map((activity, activityIndex) => (
        <Accordion
          title={`Activity #${activityIndex + 1}`}
          className="primary-item"
          key={`activity-${activityIndex}`}
          data-testid={`accordion-item-${activityIndex}`}
          isOpenByDefault={true}
        >
          {/* Overview Section */}
          <Accordion
            title="Overview"
            className="secondary-item"
            key={`overview-${activityIndex}`}
            isOpenByDefault={true}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="info-card-content row">
                  <InfoCardRow
                    title="Specialty"
                    data={project.specialty}
                    dataTestId={`specialty-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="Sub Specialty"
                    data={activity.subSpecialty}
                    dataTestId={`specialty-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="Activity Start Date"
                    data={formatDate(activity.startDate)}
                    dataTestId={`startDate-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="Activity End Date"
                    data={formatDate(activity.endDate)}
                    dataTestId={`endDate-${activityIndex}`}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-card-content row">
                  <InfoCardRow
                    title="Activity Type"
                    data={activity.activityType}
                    dataTestId={`activityType-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="Activity Contact"
                    data={
                      <Badge type="neutral">
                        <Abbreviation>
                          {activity?.clientDepartment?.firstNameContact?.[0]}
                          {activity?.clientDepartment?.lastNameContact?.[0]}
                        </Abbreviation>
                        {`${activity.clientDepartment?.firstNameContact} ${activity.clientDepartment?.lastNameContact}`}
                      </Badge>
                    }
                    dataTestId={`contact-${activityIndex}`}
                  />
                </div>
              </div>
            </div>
          </Accordion>

          {/* Rates Section */}
          <Accordion
            title="Rates"
            className="secondary-item"
            key={`rates-${activityIndex}`}
            isOpenByDefault={true}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="info-card-content row">
                  <InfoCardRow
                    title="Type"
                    data={activity.rateType}
                    dataTestId={`rateType-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="Rate"
                    data={formatCurrency(activity.rate)}
                    dataTestId={`rate-${activityIndex}`}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-card-content row">
                  <InfoCardRow
                    title="Template"
                    data={formatCurrency(activity.template)}
                    dataTestId={`template-${activityIndex}`}
                  />
                  <InfoCardRow
                    title="BCV"
                    data={formatCurrency(activity.bcv)}
                    dataTestId={`bcv-${activityIndex}`}
                  />
                </div>
              </div>
            </div>
          </Accordion>

          {/* Placements Section */}
          <Accordion
            title="Placements"
            className="secondary-item"
            key={`placements-${activityIndex}`}
            isOpenByDefault={true}
          >
            <Table data-testid={`roles-table-${activityIndex}`}>
              <thead>
                <tr>
                  {[
                    'Job Title',
                    'Grade',
                    'Start Time',
                    'End Time',
                    'Rate',
                    'Expenses Budget',
                    '',
                  ].map((header, index) => (
                    <th
                      key={index}
                      onClick={() => {}}
                      data-testid={`${header.toLowerCase()}-sort-header`}
                    >
                      <div>
                        <label>{header}</label>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {activity.roles?.length > 0 ? (
                  activity.roles.map((role, roleIndex) => (
                    <tr key={roleIndex} data-testid={`role-row-${roleIndex}`}>
                      <td>{role.level}</td>
                      <td>{role.grade}</td>
                      <td>{role.startTime}</td>
                      <td>{role.endTime}</td>
                      <td>{formatCurrency(role.rate)}</td>
                      <td>{formatCurrency(role.expanse)}</td>
                      <td>
                        <div
                          className="action-item"
                          data-testid={`role-action-${roleIndex}`}
                        >
                          <div
                            className="view-icon disabled"
                            onClick={() => {}}
                            data-testid={`view-icon-${roleIndex}`}
                          >
                            <EyeIcon />
                          </div>

                          <div
                            className="edit-icon disabled"
                            onClick={() => {}}
                            data-testid={`view-icon-${roleIndex}`}
                          >
                            <EditIcon />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="no-item-found"
                    >
                      No item found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Button
              type="success"
              onClick={() => {}}
              data-testid={`add-role-button-${activityIndex}`}
              variant="outlined"
              disabled
            >
              <PlusIcon /> Add another role
            </Button>
          </Accordion>
        </Accordion>
      ))}
      <Button
        type="success"
        onClick={() => {}}
        data-testid="add-activity-button"
        variant="outlined"
        disabled
      >
        <PlusIcon /> Add another activity
      </Button>
    </div>
  );
};
