import React, { useEffect, useMemo, useState } from 'react';
import {
  ATag,
  Button,
  FormContainer,
  ProgressBar,
  TabPageContainer,
  TitlePage,
} from '../../ui';
import { Client, Lead, Trust, User } from '../../../backend/careo-api';
import { GeneralFormStep } from './general-form-step';
import { FinancialFormStep } from './financial-form-step';
import { LegalFormStep } from './legal-form-step';
import { PlusIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';
type LeadFormProps = {
  selectedLead?: Lead;
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  getLeads: () => void;
};
const requiredFields: Array<keyof Lead> = [
  '_id',
  'name',
  'status',
  'tag',
  'type',
  'cpms',
  'region',
  'trust',
  'specialty',
  'hospital',
  'overview',
  'resourceRequirement',
  'startingMonth',
  'durationValue',
  'durationType',
  'revenue',
  'cost',
  'margin',
  'benchmark',
  'legalContact',
];

export const calculateProgressLead = (lead: Lead) => {
  // Count filled fields
  const filledFields = requiredFields.filter(
    (field) => lead[field] !== undefined && lead[field] !== null,
  ).length;

  // Calculate progress as a percentage
  return (filledFields / requiredFields.length) * 100;
};

export const LeadForm = ({
  selectedLead,
  usersList,
  trustsList,
  clientsList,
  getLeads,
}: LeadFormProps) => {
  const [step, setStep] = useState(1);
  const [createdLead, setCreatedLead] = useState<Lead>();
  const { openModal, closeModal } = useModal();

  const createProjectFromLead = (lead: Lead) => {
    // openModal({
    //   title: '',
    //   component: (
    //     <ProjectForm
    //       usersList={usersList}
    //       trustsList={trustsList}
    //       clientsList={clientsList}
    //       selectedProject={}
    //       data-testid="project-lead-form"
    //       getLeads={() => {}}
    //     />
    //   ),
    // });
  };

  const calculateProgress = useMemo(() => {
    if (!createdLead) return 0;

    return calculateProgressLead(createdLead);
  }, [createdLead]);

  useEffect(() => {
    if (selectedLead) {
      setCreatedLead(selectedLead);
    }
  }, [selectedLead]);

  return (
    <FormContainer data-testid="form-container">
      <div className="side-modal-header-container">
        <TitlePage className="side-modal-title">
          {!selectedLead ? 'New Lead' : 'Update Lead'}
        </TitlePage>
        {calculateProgress === 100 && (
          <Button onClick={() => {}} type="success" variant="outlined">
            <PlusIcon /> Create Project from Lead
          </Button>
        )}
      </div>
      <ProgressBar value={calculateProgress} data-testid="progress-bar" />
      <TabPageContainer
        className="form-tab-container"
        data-testid="tab-page-container"
      >
        <div className="tab-items-list">
          <ATag
            className={`tab-item text-capitalize ${step === 1 ? 'active' : ''}`}
            onClick={() => setStep(1)}
            data-testid="general-tab"
          >
            General
          </ATag>
          <ATag
            className={`tab-item text-capitalize ${step === 2 ? 'active' : ''} ${!createdLead?._id && 'disabled'}`}
            onClick={() => createdLead?._id && setStep(2)}
            data-testid="financial-tab"
          >
            Financial
          </ATag>
          <ATag
            className={`tab-item text-capitalize ${step === 3 ? 'active' : ''} ${!createdLead?.margin && 'disabled'}`}
            onClick={() => createdLead?.margin && setStep(3)}
            data-testid="legal-tab" // Added test ID for Legal tab
          >
            Legal
          </ATag>
        </div>
      </TabPageContainer>

      {/* Render the form fields based on the current step */}
      {step === 1 && (
        <GeneralFormStep
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          step={step}
          setStep={setStep}
          createdLead={createdLead}
          setCreatedLead={setCreatedLead}
          data-testid="general-form-step"
          getLeads={getLeads}
        />
      )}

      {step === 2 && createdLead && (
        <FinancialFormStep
          step={step}
          setStep={setStep}
          createdLead={createdLead}
          setCreatedLead={setCreatedLead}
          data-testid="financial-form-step"
          getLeads={getLeads}
        />
      )}

      {step === 3 && createdLead && (
        <LegalFormStep
          usersList={usersList}
          step={step}
          setStep={setStep}
          createdLead={createdLead}
          setCreatedLead={setCreatedLead}
          data-testid="legal-form-step"
          getLeads={getLeads}
        />
      )}
    </FormContainer>
  );
};
