import React from 'react';
import styled from 'styled-components';
import {
  DeleteIcon,
  FileIcon,
  InfoIcon,
  MailIcon,
  SavedIcon,
} from '../../icons';
import { BOX_TYPE } from '../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';

export const EmailsTypeContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e9eaec;
  background: #fff;
  .item-email-type {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    cursor: pointer;
    &.active,
    &:hover {
      background: #f5f7fb;
    }
    .item-icon {
      margin-right: 10px;
      svg {
        path {
          fill: #a0aec0;
        }
      }
    }
    .item-title {
      font-size: 14px;
      flex: 1;
    }
    .item-total {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      background: #e03137;
      color: white;
      font-size: 14px;
    }
  }
`;

type EmailsTypeListProps = {
  className?: string;
};

export const EmailsTypeList = ({ className = '' }: EmailsTypeListProps) => {
  const navigate = useNavigate();
  const { emailId, emailType } = useParams();

  return (
    <EmailsTypeContainer
      className={className}
      data-testid="emails-type-container"
    >
      <div
        className={`item-email-type ${emailType === BOX_TYPE.INBOX && 'active'}`}
        onClick={() => {
          navigate(`/emails/${BOX_TYPE.INBOX}`);
        }}
        data-testid="inbox-item"
      >
        <div className="item-icon" data-testid="inbox-icon">
          <MailIcon />
        </div>
        <div className="item-title" data-testid="inbox-title">
          Inbox
        </div>
        {/* <div className="item-total">8</div> */}
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.SENT && 'active'}`}
        onClick={() => {
          navigate(`/emails/${BOX_TYPE.SENT}`);
        }}
        data-testid="sent-item"
      >
        <div className="item-icon" data-testid="sent-icon">
          <SavedIcon />
        </div>
        <div className="item-title" data-testid="sent-title">
          Sent
        </div>
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.DRAFT && 'active'}`}
        onClick={() => {
          navigate(`/emails/${BOX_TYPE.DRAFT}`);
        }}
        data-testid="draft-item"
      >
        <div className="item-icon" data-testid="draft-icon">
          <FileIcon />
        </div>
        <div className="item-title" data-testid="draft-title">
          Drafts
        </div>
        {/* <div className="item-total">2</div> */}
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.SPAM && 'active'}`}
        onClick={() => {
          navigate(`/emails/${BOX_TYPE.SPAM}`);
        }}
        data-testid="spam-item"
      >
        <div className="item-icon" data-testid="spam-icon">
          <InfoIcon />
        </div>
        <div className="item-title" data-testid="spam-title">
          Spam
        </div>
        {/* <div className="item-total">1</div> */}
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.TRASH && 'active'}`}
        onClick={() => {
          navigate(`/emails/${BOX_TYPE.TRASH}`);
        }}
        data-testid="trash-item"
      >
        <div className="item-icon" data-testid="trash-icon">
          <DeleteIcon />
        </div>
        <div className="item-title" data-testid="trash-title">
          Trash
        </div>
        {/* <div className="item-total">8</div> */}
      </div>
    </EmailsTypeContainer>
  );
};
