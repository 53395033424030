import React from 'react';
import { Project } from '../../backend/careo-api';
import { RevenueChart } from './charts/revenue-chart';
import { Abbreviation, Badge, InfoCardRow } from '../ui';

type ProjectDetailsFinancialProps = {
  project: Project;
};

export const ProjectDetailsFinancial = ({
  project,
}: ProjectDetailsFinancialProps) => {
  return (
    <>
      <div className="info-card" data-testid="financials-card">
        <div className="info-card-title" data-testid="financials-title">
          Financials
        </div>
        <hr />
        <div className="info-card-content" data-testid="financials-content">
          <div className="row">
            <div className="col-lg-4" data-testid="revenue-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Revenue',
                    backgroundColor: '#11af60',
                    borderColor: '#11af60',
                    value: project.revenue ?? 0,
                    percentage: 6.99,
                  },
                ]}
                data-testid="revenue-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="cost-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Cost',
                    backgroundColor: '#FFAE00',
                    borderColor: '#FFAE00',
                    value: project.cost ?? 0,
                    percentage: 5.28,
                  },
                ]}
                data-testid="cost-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="margin-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Margin',
                    backgroundColor: '#8C62FF',
                    borderColor: '#8C62FF',
                    value: project.margin ?? 0,
                    percentage: 24.4,
                  },
                ]}
                data-testid="margin-chart-component"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="info-card" data-testid="invoicing-card">
        <div className="info-card-title" data-testid="invoicing-title">
          Invoicing
        </div>
        <hr />
        <div className="row" data-testid="invoicing-row">
          <div className="col-md-6" data-testid="client-info-col">
            <div className="info-card-content row" data-testid="client-info">
              <InfoCardRow
                title="Contact"
                data={
                  project.contact ? (
                    <Badge type="neutral">
                      <Abbreviation>
                        {project.contact.firstName[0]}
                        {project.contact.lastName[0]}
                      </Abbreviation>
                      {project.contact.firstName} {project.contact.lastName}
                    </Badge>
                  ) : null
                }
                dataTestId="client-name"
              />
              <InfoCardRow
                title="Staff Pay Frequency"
                data={project.staffPayFrequency}
                dataTestId="staffPayFrequency"
              />
              <InfoCardRow
                title="Payment Terms"
                data={project.paymentTerms}
                dataTestId="hospital"
              />
            </div>
          </div>
          <div className="col-md-6" data-testid="service-info-col">
            <div className="info-card-content row" data-testid="service-info">
              <InfoCardRow
                title="Invoicing To"
                data={project.invoicingTo}
                dataTestId="cpm"
              />
              <InfoCardRow
                title="CC:"
                data={project.invoicingCC}
                dataTestId="service-coordinator"
              />
              <InfoCardRow
                title="Invoice Frequency"
                data={project.invoiceFrequency}
                dataTestId="cem"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
