import React, { useEffect, useMemo, useState } from 'react';

import { DeleteIcon, EditIcon, EyeIcon, SortIcon } from '../../icons';
import { Application } from '../../backend/careo-api';
import {
  ERoute,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
} from '../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../ui';
import { useConfirm } from '../../contexts/confirm-modal.context';

export const ProjectMatchingList = ({ itemsPerPage = 8 }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');

  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [applicationsList, setApplicationsList] = useState<Application[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationToUpdate, setApplicationToUpdate] = useState<Application>();

  const { openConfirm, closeConfirm } = useConfirm();
  const onDeleteConfirm = (item: Application) => {
    // openConfirm({
    //   title: 'Delete User',
    //   component: (
    //     <>
    //       Do you want to delete the department of
    //       <b data-testid="confirm-delete-department-name">
    //         {/* {item?.firstNameAccount} */}
    //       </b>
    //       ?
    //     </>
    //   ),
    //   onConfirm: () => deleteApplication(item),
    // });
  };

  const [filter, setFilter] = useState<any>({
    search: '',
    levels: [],
    grades: [],
    statuses: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const onClickAddNew = () => {
    setIsModalOpen(true);
  };

  const onClickEdit = (application: Application) => {
    setApplicationToUpdate(application);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PLACEMENTS}/${id}`);
  };

  const deleteApplication = async () => {
    // await AxiosInstance.applications
    //   .applicationsControllerDeleteApplication(recordToDelete?._id!)
    //   .then(() => {
    //     toast.success('Application Removed successfully');
    //     getApplications();
    //   })
    //   .catch((error: AxiosInstanceErrorResponse) => {
    //     toast.error(error.message);
    //   });
    closeConfirm();
  };

  const getApplications = async () => {
    // await AxiosInstance.applications
    //   .applicationsControllerFindAll()
    //   .then((response) => {
    //     const result = response.data.items;
    //     setApplicationsList(result);
    //   })
    //   .catch((error: AxiosInstanceErrorResponse) => {
    //     toast.error(error.message);
    //   });
    setIsLoading(false);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(applicationsList, currentPage, itemsPerPage);
  }, [applicationsList, currentPage]);

  useEffect(() => {
    // const filteredData = filterApplications(applications, filter);
    // const sortedData = sortApplications(filteredData, sort);
    setApplicationsList(applications);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(applications.length, itemsPerPage));
  }, [applications, filter, sort]);

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <div className="data-table-container" data-testid="data-table-container">
      <Table data-testid="table">
        <thead>
          <tr data-testid="table-header">
            <th className="checkbox-table" data-testid="checkbox-column">
              <input type="checkbox" data-testid="checkbox-input" />
            </th>
            <th
              onClick={() => onSelectSort('firstName', setSort)}
              data-testid="employee-name-column"
            >
              <div>
                <label>Employee Name</label>
                <SortIcon
                  value={sort.key === 'firstName' ? sort.value : ''}
                  data-testid="employee-name-sort"
                />
              </div>
            </th>
            <th
              onClick={() => onSelectSort('grade', setSort)}
              data-testid="application-column"
            >
              <div>
                <label>Application</label>
                <SortIcon
                  value={sort.key === 'grade' ? sort.value : ''}
                  data-testid="application-sort"
                />
              </div>
            </th>
            <th
              onClick={() => onSelectSort('availableFrom', setSort)}
              data-testid="from-column"
            >
              <div>
                <label>From</label>
                <SortIcon
                  value={sort.key === 'availableFrom' ? sort.value : ''}
                  data-testid="from-sort"
                />
              </div>
            </th>

            <th
              onClick={() => onSelectSort('availableTo', setSort)}
              data-testid="to-column"
            >
              <div>
                <label>To</label>
                <SortIcon
                  value={sort.key === 'availableTo' ? sort.value : ''}
                  data-testid="to-sort"
                />
              </div>
            </th>
            <th
              onClick={() => onSelectSort('clientName', setSort)}
              data-testid="client-name-column"
            >
              <div>
                <label>Client Name</label>
                <SortIcon
                  value={sort.key === 'clientName' ? sort.value : ''}
                  data-testid="client-name-sort"
                />
              </div>
            </th>
            <th
              onClick={() => onSelectSort('client-firstName', setSort)}
              data-testid="lead-contact-column"
            >
              <div>
                <label>Lead Contact</label>
                <SortIcon
                  value={sort.key === 'client-firstName' ? sort.value : ''}
                  data-testid="lead-contact-sort"
                />
              </div>
            </th>
            <th data-testid="status-column">
              <div>
                <label>Status</label>
                <SortIcon data-testid="status-sort" />
              </div>
            </th>
            <th data-testid="action-column">
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="loading-row"
              >
                Loading ...
              </td>
            </tr>
          ) : (
            <>
              {paginatedData.length > 0 ? (
                <>
                  {paginatedData.map((application) => {
                    return (
                      <tr
                        key={application?._id}
                        data-testid={`application-row-${application?._id}`}
                      >
                        <td
                          className="checkbox-table"
                          data-testid={`checkbox-${application?._id}`}
                        >
                          <input
                            type="checkbox"
                            data-testid={`checkbox-input-${application?._id}`}
                          />
                        </td>
                        <td data-testid={`employee-name-${application?._id}`}>
                          <div className="name-item">
                            <div>
                              <div>
                                {application.candidate?.firstName}{' '}
                                {application.candidate?.lastName}
                              </div>
                              <div className="email">
                                {application.candidate?.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          data-testid={`application-grade-${application?._id}`}
                        >
                          <div className="name-item">
                            <div>
                              <div>{application.job?.grade}</div>
                              <div className="email">
                                {application.job?.specialty}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td data-testid={`available-from-${application?._id}`}>
                          {formatDate(application.availableFrom)}
                        </td>
                        <td data-testid={`available-to-${application?._id}`}>
                          {formatDate(application.availableTo)}
                        </td>
                        <td data-testid={`client-name-${application?._id}`}>
                          {application.job?.client?.clientName}
                        </td>
                        <td data-testid={`lead-contact-${application?._id}`}>
                          <div className="name-item">
                            <div>
                              <div>
                                {application.job?.client?.firstName}{' '}
                                {application.job?.client?.lastName}
                              </div>
                              <div className="email">
                                {application.job?.client?.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td data-testid={`status-${application?._id}`}>
                          {/* <ApplicationStatusBadge
                            approvalStatus={
                              application.approvalStatus ??
                              EApplicationApprovalStatus.NOT_ACTIVE
                            }
                          /> */}
                        </td>
                        <td data-testid={`action-icons-${application?._id}`}>
                          <div className="action-item">
                            <div
                              className="view-icon"
                              onClick={() => onClickView(application?._id)}
                              data-testid={`view-icon-${application?._id}`}
                            >
                              <EyeIcon />
                            </div>
                            <div
                              className="edit-icon"
                              onClick={() => onClickEdit(application)}
                              data-testid={`edit-icon-${application?._id}`}
                            >
                              <EditIcon />
                            </div>
                            <div
                              className="delete-icon"
                              onClick={() => onDeleteConfirm(application)}
                              data-testid={`delete-icon-${application?._id}`}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="no-item-row"
                  >
                    No item found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};
