import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { Link, useNavigate } from 'react-router-dom';
import { SignInDto } from '../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AuthSchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
} from '../../utils';
import { Button, FieldText, TitlePage } from '../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { AppIcon } from '../../icons';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInDto>({
    resolver: yupResolver(AuthSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string>();

  const onClickLogin = async () => {
    const values = getValues();
    await AxiosInstance.auth
      .authControllerSignIn(values)
      .then((response) => {
        if (response.data.otpRequired) {
          navigate(`/${ERoute.VERIFY_OTP}`, { state: { email: values.email } });
        } else {
          login(response.data);
        }
      })
      .catch((e: AxiosInstanceErrorResponse) => {
        if (e.status === 401) {
          setErrorMessage(
            'Wrong Email or Password please check your credentials',
          );
          setError('email', { message: '' });
          setError('password', { message: '' });
        } else if (e.status === 400) {
          setErrorMessage(
            'Wrong Email or Password please check your credentials',
          );
          setError('email', { message: '' });
          setError('password', { message: '' });
        } else {
          toast.error(e.message);
        }
      });
  };

  return (
    <div className="content-container" data-testid="content-container">
      <div className="mobile-logo-container">
        <AppIcon data-testid="app-icon" />
      </div>
      <TitlePage className="text-center" data-testid="title-page">
        Login first to your account
      </TitlePage>
      <br />
      <br />
      <br />
      <div className="email-container" data-testid="email-container">
        <div className="input-container" data-testid="email-input-container">
          <FieldText
            placeholder="Enter here ..."
            type="email"
            label="Email Address"
            register={register('email', { required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(onClickLogin)();
            }}
            error={errors.email}
            data-testid="email-input"
          />
        </div>
      </div>
      <div className="email-container" data-testid="password-container">
        <div className="input-container" data-testid="password-input-container">
          <FieldText
            placeholder="Enter here ..."
            type="password"
            label="Password"
            register={register('password', { required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(onClickLogin)();
            }}
            error={errors.password}
            data-testid="password-input"
          />
        </div>
      </div>
      <div
        className="forgot-remember-container"
        data-testid="forgot-remember-container"
      >
        <div className="remember-container" data-testid="remember-container">
          <input type="checkbox" data-testid="remember-checkbox" />
          Remember me
        </div>
        <Link
          to={`/${ERoute.FORGOT_PASSWORD}`}
          className="forgot-password"
          data-testid="forgot-password-link"
        >
          Forgot Password
        </Link>
      </div>
      {errorMessage && (
        <span
          style={{
            color: 'red',
            fontSize: '12px',
          }}
          data-testid="error-message"
        >
          {errorMessage}
        </span>
      )}
      <Button
        type="primary"
        onClick={handleSubmit(onClickLogin)}
        data-testid="login-button"
      >
        Login
      </Button>
    </div>
  );
};
