import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const TabFilterContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  gap: 20px;
  .filter-item {
    padding: 10px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 5px;
    cursor: pointer;
    &:hover {
      color: #27a376;
      opacity: 0.9;
    }
    &.active {
      color: #27a376;
      border-bottom: 2px solid #27a376;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    display: flex;
    gap: 20px;
    .filter-item {
      padding: 5px 10px;
      font-size: 10px;
      padding: 5px 5px;
    }
  }
`;

type TabFilterProps = {
  filters: { title: string; url: string }[];
};

export const TabFilter = ({ filters }: TabFilterProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!filters.length) {
    return <></>;
  }

  return (
    <TabFilterContainer data-testid="tab-filter-container">
      {filters.map((el, index) => {
        const url = '/' + el.url;
        const isActive = url === pathname || pathname.includes(url + '/');
        return (
          <div
            className={`filter-item ${isActive && 'active'}`}
            onClick={() => {
              !isActive && navigate('/' + el.url);
            }}
            key={index}
            data-testid={`filter-item-${index}`}
          >
            {el.title}
          </div>
        );
      })}
    </TabFilterContainer>
  );
};
