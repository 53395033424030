import React, { useEffect, useState } from 'react';
import { FieldText, Button, CardContainer, TitlePage, RadioGroup } from '../ui';
import {
  EFrequencyTimesheet,
  TimesheetSettings,
} from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { daysOfWeekOptions } from '../../constants';

export const TimesheetSetting = () => {
  const [timesheetSettings, setTimesheetSettings] =
    useState<TimesheetSettings>();

  const getTimesheetSettings = async () => {
    await AxiosInstance.timesheets
      .timesheetsControllerGetTimesheetSettings()
      .then((response) => {
        const result = response.data;
        if (result) {
          setTimesheetSettings({
            ...result,
            time: result.time,
          });
        }
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    if (!timesheetSettings?.frequency || !timesheetSettings?.time) {
      toast.error('Please fill the data');
      return;
    }
    setIsSubmitting(true);

    await AxiosInstance.timesheets
      .timesheetsControllerUpdateTimesheetSettings({
        ...timesheetSettings,
        time: timesheetSettings.time,
      })
      .then(() => {
        toast.success('Timesheet Settings updated successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });

    setIsSubmitting(false);
  };

  useEffect(() => {
    getTimesheetSettings();
  }, []);

  return (
    <CardContainer className="right-container" data-testid="card-container">
      <div
        className="settings-title-content"
        data-testid="settings-title-content"
      >
        <TitlePage>Timesheet</TitlePage>
      </div>
      <hr />
      <div className="settings-content" data-testid="settings-content">
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <TitlePage data-testid="timesheet-frequency-title">
            Select your timesheet frequency
          </TitlePage>
          <br />
          <RadioGroup
            data-testid="timesheet-frequency-radio-group"
            options={[
              {
                label: 'Every Day',
                value: EFrequencyTimesheet.Daily,
                description:
                  'Timesheet for each placement will be generated for export every 24hrs. This will be generated based on approved placements in the system and associated with each candidate.',
              },
              {
                label: 'Weekly',
                value: EFrequencyTimesheet.Weekly,
                description:
                  'Timesheet for each placement will be generated for export every 7 days. This will be generated based on approved timesheets logged in the system and associated with each candidate.',
              },
            ]}
            name="frequencyTimesheet"
            value={timesheetSettings?.frequency}
            onChange={(e) =>
              setTimesheetSettings((prev: any) => ({
                ...prev,
                frequency: e,
              }))
            }
          />
          <br />
          <div className="days-container" data-testid="days-container">
            {timesheetSettings?.frequency === EFrequencyTimesheet.Weekly && (
              <RadioGroup
                data-testid="weekly-day-radio-group"
                options={daysOfWeekOptions}
                name="dayTimesheet"
                onChange={(value) =>
                  setTimesheetSettings((prev: any) => ({
                    ...prev,
                    dayOfTheWeek: Number(value),
                  }))
                }
                value={timesheetSettings?.dayOfTheWeek}
              />
            )}
            <br />
            <FieldText
              data-testid="time-input"
              placeholder="Enter here ..."
              label="Time (GMT)"
              required
              type="time"
              onChange={(e) =>
                setTimesheetSettings((prev: any) => ({
                  ...prev,
                  time: e.target.value,
                }))
              }
              value={timesheetSettings?.time}
            />
          </div>
          <div className="setting-actions" data-testid="setting-actions">
            <Button
              type="primary"
              onClick={onClickSubmit}
              disabled={isSubmitting}
              data-testid="save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
