import * as yup from 'yup';

export const AuthSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),

  password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters')
    .max(20, 'Password must be at most 20 characters'),
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
  //     'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
  //   ),
});

export const ForgetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters')
    .max(20, 'Password must be at most 20 characters'),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
});
