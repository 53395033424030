import React, { useEffect, useState } from 'react';
import { LeftArrowIcon } from '../../../icons';
import {
  ATag,
  CardContainer,
  TitlePage,
  DragDropUpload,
  TabPageContainer,
  DocumentsListComponent,
} from '../../../components';
import { VacancyDetailsContainer } from './vacancy-details.style';
import { AxiosInstance, formatDate } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Job } from '../../../backend/careo-api';
import { toast } from 'react-toastify';

enum EVacancyDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  AVAILABILITY = 'Availability',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
}

const tabs = [
  EVacancyDetailsTabs.GENERAL,
  EVacancyDetailsTabs.CONTACT,
  EVacancyDetailsTabs.DOCUMENTS,
];

export const VacancyDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EVacancyDetailsTabs.GENERAL);
  const [vacancy, setVacancy] = useState<Job | null>(null);

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setVacancy(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getVacancyDetails();
  }, []);

  if (!vacancy) {
    return <></>;
  }

  return (
    <VacancyDetailsContainer data-testid="vacancy-details-container">
      <div className="vacancy-header" data-testid="vacancy-header">
        <div
          className="back-button"
          onClick={onClickGoBack}
          data-testid="back-button"
        >
          <LeftArrowIcon />
        </div>
        <TitlePage data-testid="vacancy-title">Detail Vacancy</TitlePage>
      </div>
      <CardContainer
        className="vacancy-information-container"
        data-testid="vacancy-information-container"
      >
        <TabPageContainer data-testid="tab-page-container">
          <div className="tab-items-list" data-testid="tab-items-list">
            {tabs.map((el) => (
              <ATag
                className={`tab-item ${el === selectedTab && 'active'}`}
                onClick={() => setSelectedTab(el)}
                data-testid={`tab-item-${el}`}
                key={el}
              >
                {el}
              </ATag>
            ))}
          </div>
          <div
            className="tab-content-container"
            data-testid="tab-content-container"
          >
            {selectedTab === EVacancyDetailsTabs.GENERAL && (
              <>
                <div
                  className="info-card"
                  data-testid="general-vacancy-details"
                >
                  <div
                    className="info-card-title"
                    data-testid="info-card-title"
                  >
                    Vacancy Details
                  </div>
                  <hr data-testid="vacancy-details-divider" />
                  <div
                    className="info-card-content row"
                    data-testid="vacancy-details-content"
                  >
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-client-name"
                    >
                      <div className="info-item-title col-4">Client Name</div>
                      <div className="info-item-data col-8">
                        {vacancy?.client?.clientName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-specialty"
                    >
                      <div className="info-item-title col-4">Specialty</div>
                      <div className="info-item-data col-8">
                        {vacancy.specialty}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-job-description"
                    >
                      <div className="info-item-title col-4">
                        Job Description
                      </div>
                      <div className="info-item-data col-8">
                        {vacancy.jobDescription}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="vacancy-grade">
                      <div className="info-item-title col-4">Grade</div>
                      <div className="info-item-data col-8">
                        {vacancy.grade}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-start-date"
                    >
                      <div className="info-item-title col-4">Start Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(vacancy.vacantFrom)}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-end-date"
                    >
                      <div className="info-item-title col-4">End Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(vacancy.vacantTo)}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="vacancy-pay-option"
                    >
                      <div className="info-item-title col-4">Pay Option</div>
                      <div className="info-item-data col-8">
                        {vacancy.payOption}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="vacancy-vat">
                      <div className="info-item-title col-4">VAT</div>
                      <div className="info-item-data col-8">{vacancy.vat}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedTab === EVacancyDetailsTabs.CONTACT && (
              <div className="info-card" data-testid="contact-details">
                <div
                  className="info-card-title"
                  data-testid="contact-details-title"
                >
                  Contact Details
                </div>
                <hr data-testid="contact-details-divider" />
                <div
                  className="info-card-content row"
                  data-testid="contact-details-content"
                >
                  <div className="col-md-6 row" data-testid="contact-title">
                    <div className="info-item-title col-4">Title</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.title}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-first-name"
                  >
                    <div className="info-item-title col-4">First Name</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.firstName}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-last-name">
                    <div className="info-item-title col-4">Last Name</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.lastName}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-job-title">
                    <div className="info-item-title col-4">Job Title</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.jobTitle}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-email">
                    <div className="info-item-title col-4">Email Address</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.email}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-phone-number"
                  >
                    <div className="info-item-title col-4">Mobile Number</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.phoneNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-work-phone-number"
                  >
                    <div className="info-item-title col-4">
                      Work Phone Number
                    </div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.workPhoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-website">
                    <div className="info-item-title col-4">Website</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.website}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-fax-number"
                  >
                    <div className="info-item-title col-4">Fax Number</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.faxNumber}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === EVacancyDetailsTabs.DOCUMENTS && (
              <>
                <div className="info-card" data-testid="personal-documents">
                  <div
                    className="info-card-title"
                    data-testid="personal-documents-title"
                  >
                    Personal Documents
                  </div>
                  <hr data-testid="documents-divider" />
                  <DocumentsListComponent
                    documents={[]}
                    onClickDelete={() => {}}
                    getDocuments={() => {}}
                    data-testid="documents-list"
                  />
                </div>
                <div className="info-card" data-testid="drag-drop-upload">
                  <DragDropUpload onUpload={(files: File[]) => ({}) as any} />
                </div>
              </>
            )}
          </div>
        </TabPageContainer>
      </CardContainer>
    </VacancyDetailsContainer>
  );
};
