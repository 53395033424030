import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AddFinancialLeadDto, Lead } from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { financialLeadSchema } from '../../../utils/validators/leads.validator';
import { useForm } from 'react-hook-form';
import { Badge, Button, FieldText } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';

type FinancialFormStepProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdLead: Lead;
  setCreatedLead: Dispatch<SetStateAction<Lead | undefined>>;
  getLeads: () => void;
};

export const FinancialFormStep = ({
  step,
  setStep,
  createdLead,
  setCreatedLead,
  getLeads,
}: FinancialFormStepProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,

    watch,
  } = useForm<AddFinancialLeadDto>({
    resolver: yupResolver(financialLeadSchema),
  });

  const revenue = watch('revenue');
  const cost = watch('cost');

  const marginPercentage = useMemo(() => {
    if (revenue && cost) {
      const marginValue = ((revenue - cost) / revenue) * 100;
      return marginValue.toFixed(2);
    }
    return null;
  }, [cost, revenue]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmitFinancialLead = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto: AddFinancialLeadDto = {
      revenue: Number(values.revenue),
      margin: Number(values.margin),
      cost: Number(values.cost),
      benchmark: Number(values.benchmark),
    };

    await AxiosInstance.leads
      .leadsControllerAddFinancialLeadyId(createdLead._id, requestDto)
      .then((response) => {
        setCreatedLead(response.data);
        if (isWithExist) {
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('Financial Lead saved successfully');
        getLeads();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdLead) {
      reset({
        ...createdLead,
      });
    }
  }, [createdLead]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Revenue"
            required
            register={register('revenue')}
            error={errors.revenue}
            data-testid="revenue-input"
            type="currency"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Cost"
            required
            register={register('cost')}
            error={errors.cost}
            data-testid="cost-input" // Added test ID for Cost field
            type="currency"
          />
        </div>
        <div className="col-md-6 margin-container">
          <FieldText
            placeholder="Enter here ..."
            label="Margin"
            required
            register={register('margin')}
            error={errors.margin}
            type="currency"
            data-testid="margin-input" // Added test ID for Margin field
          />
          <Badge type="success">{marginPercentage}%</Badge>
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Cashflow Benchmark 90 Days"
            required
            register={register('benchmark')}
            error={errors.benchmark}
            type="currency"
            data-testid="benchmark-input" // Added test ID for Cashflow Benchmark field
          />
        </div>
      </div>

      <div className="form-actions stepper" data-testid="form-actions">
        <div className="left-container">
          <Button
            onClick={handleSubmit(() => onSubmitFinancialLead(true))}
            type="primary"
            variant="outlined"
            data-testid="save-exit-button"
          >
            Save & Exit
          </Button>
          <Button
            type="danger"
            variant="outlined"
            onClick={() => closeModal()}
            data-testid="cancel-button"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button onClick={() => setStep(1)}>
            <LeftArrowIcon /> Back
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitFinancialLead(false))}
            data-testid="next-button" // Added test ID for Next button
            disabled={isSubmitting}
          >
            Next <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};
