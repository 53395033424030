import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Client,
  CreateGeneralProjectDto,
  Project,
  Trust,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, FieldText, MultipleSelect, Select, Textarea } from '../../ui';

import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  generalProjectSchema,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { useModal } from '../../../contexts/side-modal.context';
import { RightArrowIcon } from '../../../icons';
import { regionsWithCounties, specialtiesList } from '../../../constants';

type GeneralFormStepProps = {
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdProject: Project | undefined;
  setCreatedProject: Dispatch<SetStateAction<Project | undefined>>;
  getProjects: () => void;
};

export const GeneralFormStep = ({
  usersList,
  trustsList,
  clientsList,
  step,
  setStep,
  createdProject,
  setCreatedProject,
  getProjects,
}: GeneralFormStepProps) => {
  const { closeModal } = useModal();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<CreateGeneralProjectDto>({
    resolver: yupResolver(generalProjectSchema),
  });

  const trustId = watch('trustId');

  const hospitalOptions = useMemo(() => {
    if (trustId) {
      return clientsList.filter((el) => el.trust?._id === trustId);
    }
    return clientsList;
  }, [clientsList, trustId]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmitGeneralProject = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto = {
      ...values,
    };

    const ProjectRequest = () =>
      createdProject
        ? AxiosInstance.projects.projectsControllerUpdateById(
            createdProject._id,
            requestDto,
          )
        : AxiosInstance.projects.projectsControllerCreate(requestDto);

    await ProjectRequest()
      .then((response) => {
        setCreatedProject(response.data);
        if (isWithExist) {
          getProjects();
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('General Project saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdProject) {
      reset({
        ...createdProject,
        cpmIds: createdProject.cpms.map((el) => el._id),
        clientId: createdProject.client._id,
        trustId: createdProject?.trust?._id ?? undefined,
        serviceCoordinatorId: createdProject.serviceCoordinator._id,
        cemId: createdProject.cem._id,
      });
    } else {
      reset({});
    }
  }, [createdProject]);

  return (
    <>
      <div className="row" data-testid="row-name">
        <div className="col-md-6" data-testid="col-client">
          <FieldText
            placeholder="Enter here ..."
            label="Name"
            data-testid="enter-name"
            required
            register={register('name')}
            error={errors.name}
          />
        </div>
        <div className="col-md-6" data-testid="col-specialty">
          <Select
            placeholder="Enter here ..."
            label="Specialty"
            required
            register={register('specialty')}
            error={errors.specialty}
            control={control}
            options={specialtiesList.map((el) => ({
              label: el,
              value: el,
            }))}
          />
        </div>
        <div className="col-12">
          <MultipleSelect
            placeholder="Enter here ..."
            label="Select CPM(s)"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            register={register('cpmIds')}
            control={control}
            required
            error={errors.cpmIds as any}
          />
        </div>
        <div className="col-md-6" data-testid="col-service-coordinator">
          <Select
            placeholder="Select service coordinator"
            label="Service Coordinator"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            data-testid="select-service-coordinator"
            register={register('serviceCoordinatorId')}
            control={control}
            required
            error={errors.serviceCoordinatorId}
          />
        </div>
        <div className="col-md-6" data-testid="col-service-cem">
          <Select
            placeholder="Select CEM"
            label="Service CEM"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            data-testid="select-service-cem"
            register={register('cemId')}
            control={control}
            required
            error={errors.cemId}
          />
        </div>
        <div className="col-md-6" data-testid="col-region">
          <Select
            placeholder="Enter here ..."
            label="Region"
            options={regionsWithCounties.map((el) => ({
              value: el.region,
              label: el.region,
            }))}
            register={register('region')}
            control={control}
            required
            error={errors.region}
          />
        </div>
        <div className="col-md-6" data-testid="col-trust">
          <Select
            placeholder="Enter here ..."
            label="Trust"
            options={trustsList.map((el) => ({
              value: el._id,
              label: el.name,
            }))}
            register={register('trustId')}
            control={control}
            error={errors.trustId}
          />
        </div>
        <div className="col-md-12" data-testid="col-hospital">
          <Select
            placeholder="Enter here ..."
            label="Hospital"
            options={hospitalOptions.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            register={register('clientId')}
            control={control}
            required
            error={errors.clientId}
          />
        </div>

        <div className="col-md-12" data-testid="col-reporting-instructions">
          <Textarea
            label={'Reporting Instructions'}
            placeholder="Enter here ..."
            data-testid="textarea-reporting-instructions"
            required
            register={register('report')}
            error={errors.report}
          />
        </div>
      </div>

      <div className="form-actions stepper" data-testid="form-actions">
        <div className="left-container">
          <Button
            onClick={handleSubmit(() => onSubmitGeneralProject(true))}
            type="primary"
            variant="outlined"
            data-testid="save-exit-button"
          >
            Save & Exit
          </Button>
          <Button
            type="danger"
            variant="outlined"
            onClick={() => {
              getProjects();
              closeModal();
            }}
            data-testid="cancel-button"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitGeneralProject(false))}
            data-testid="next-button"
            disabled={isSubmitting}
          >
            Next <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};
