import React from 'react';
import styled from 'styled-components';

interface AlertProps {
  type: 'success' | 'danger' | 'warning' | 'info'; // Bootstrap alert types
  message: string | React.ReactNode;
  dismissible?: boolean;
}

const AlertContainer = styled.div`
  a {
    color: unset !important;
  }
`;

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  dismissible = true,
}) => {
  return (
    <AlertContainer
      className={`alert alert-${type} ${
        dismissible ? 'alert-dismissible fade show' : ''
      }`}
      role="alert"
      data-testid="alert-container"
    >
      {message}
      {dismissible && (
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          data-testid="dismiss-alert-button"
        ></button>
      )}
    </AlertContainer>
  );
};
