import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { ForgetPasswordDto } from '../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  ForgetPasswordSchema,
} from '../../utils';
import { Button, FieldText, TitlePage } from '../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AppIcon } from '../../icons';

export const ForgetPasswordPage = () => {
  const {} = useAuth();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgetPasswordDto>({
    resolver: yupResolver(ForgetPasswordSchema),
  });

  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onClickForgetPassword = async () => {
    const values = getValues();
    await AxiosInstance.auth
      .authControllerForgetPassword(values)
      .then((response) => {
        toast.success(
          `A password reset link has been sent. Please check your inbox.`,
        );
        setIsSent(true);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="content-container" data-testid="content-container">
      <div className="mobile-logo-container">
        <AppIcon data-testid="app-icon" />
      </div>
      <TitlePage className="text-center" data-testid="title-page">
        Reset your account
      </TitlePage>
      <br />
      <br />
      <br />
      <br />
      <div className="email-container" data-testid="email-container">
        <div className="input-container" data-testid="input-container">
          <FieldText
            type="email"
            placeholder="Enter here ..."
            label="Email Address"
            register={register('email', { required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(onClickForgetPassword)();
            }}
            error={errors.email}
            disabled={isSent}
            data-testid="email-input"
          />
        </div>
      </div>
      {errorMessage && (
        <span
          style={{
            color: 'red',
            fontSize: '12px',
          }}
          data-testid="error-message"
        >
          {errorMessage}
        </span>
      )}
      {isSent ? (
        <div className="register-option" data-testid="email-sent-message">
          Email is sent successfully
        </div>
      ) : (
        <Button
          type="primary"
          onClick={handleSubmit(onClickForgetPassword)}
          data-testid="reset-password-button"
        >
          Reset password
        </Button>
      )}
      <div className="cancel-container " data-testid="cancel-container">
        <Link
          to={`/${ERoute.LOGIN}`}
          className="cancel-link"
          data-testid="cancel-link"
        >
          Click here to cancel
        </Link>
      </div>
    </div>
  );
};
