import React from 'react';
import styled from 'styled-components';
import { DotsIcon } from '../../../icons';
import { Badge } from '../../ui';

export const RotasCardContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #337e89;
  background: rgba(51, 126, 137, 0.05);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  .left-container {
    .name {
      font-size: 12px;
      font-weight: 400;
    }
    .time {
      color: #747474;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .right-container {
    display: flex;
  }
`;

export const RotasCard = () => {
  return (
    <RotasCardContainer>
      <div className="left-container" data-testid="left-container">
        <div className="name" data-testid="rota-name">
          Gemma Flowerdew
        </div>
        <div className="time" data-testid="rota-time">
          8:00 - 16:00
        </div>
      </div>
      <div className="right-container" data-testid="right-container">
        <Badge type="success" data-testid="badge-complete">
          Complete
        </Badge>
        <DotsIcon data-testid="dots-icon" />
      </div>
    </RotasCardContainer>
  );
};
