import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  CardContainer,
  MessageList,
  SearchInput,
  MessageContent,
} from '../../components';
import { MessagesPageContainer } from './messages.style';
import {
  Candidate,
  ChatListItemDto,
  Client,
  HostedFile,
  EWebsocketType,
  WhatsappCandidatesCRMChat,
  MEMBER_TYPE,
  SocketMessageEvent,
  SocialPlatforms,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  sendWhatsappRequest,
  socket,
} from '../../utils';
import { PagesUrls } from '../../routes/page-urls';
import { isCRMApp } from '../../environment/app.type';
import { toast } from 'react-toastify';

type MessagesPageProps = {
  type: MEMBER_TYPE;
};

export enum MessageSender {
  Candidate = 'candidate',
  Client = 'client',
  User = 'user',
}

export interface WhatsappMessageUI
  extends Omit<WhatsappCandidatesCRMChat, 'candidate'> {
  receiverId: string;
  isLoading: boolean;
}

export const MessagesPage = ({
  type: selectedMemberType,
}: MessagesPageProps) => {
  const navigate = useNavigate();
  const { memberId: selectedMemberId } = useParams();

  const [platform, setPlatform] = useState(SocialPlatforms.Whatsapp);
  const [search, setSearch] = useState('');

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const [recentMessages, setRecentMessages] = useState<{
    isLoading: boolean;
    data: ChatListItemDto[];
  }>({ isLoading: true, data: [] });

  const [messages, setMessages] = useState<{
    isLoading: boolean;
    data: WhatsappMessageUI[];
  }>({ isLoading: true, data: [] });

  const selectedMember: Client | Candidate = useMemo(() => {
    const members: any[] =
      selectedMemberType === MEMBER_TYPE.Candidate ? candidates : clients;
    return members.find((c) => c._id === selectedMemberId);
  }, [selectedMemberId, candidates, clients]);

  const filteredRecentMessages = useMemo(
    () =>
      recentMessages.data.filter((el) =>
        (el.member.firstName + ' ' + el.member.lastName)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      ),
    [search, recentMessages],
  );

  const getLogs = async (memberId: string) => {
    setMessages({ isLoading: true, data: [] });
    await AxiosInstance.socialPlatforms
      .socialPlatformsControllerFetchMemberChats(
        platform,
        process.env.REACT_APP_NAME!,
        selectedMemberType,
        memberId,
        // {
        //   box: '',
        // },
      )
      .then(async (response) => {
        setMessages({
          isLoading: false,
          data: response.data as unknown as WhatsappMessageUI[],
        });

        AxiosInstance.socialPlatforms
          .socialPlatformsControllerMarkChatAsRead(
            platform,
            process.env.REACT_APP_NAME!,
            selectedMemberType,
            {
              memberId,
            },
          )
          .catch((error: AxiosInstanceErrorResponse) => {
            console.log('error');
            console.log(error);
          });

        setRecentMessages((prev) => {
          const index = prev.data.findIndex(
            (el) => el.member._id === memberId && el.unreadMessagesCount,
          );

          if (index >= 0) {
            const updatedData = [...prev.data];
            updatedData[index] = {
              ...updatedData[index],
              unreadMessagesCount: 0,
            };
            return { ...prev, data: updatedData };
          }

          return prev;
        });
      })
      .catch(() => {
        setMessages({ isLoading: false, data: [] });
      });
  };

  //  #################### MESSAGES ####################
  const sendMessageAndAttachmentWhatsapp = (
    recipientId: string,
    message: string,
    attachment?: File,
  ) => {
    if (!attachment && !message) {
      return;
    }

    const messageObject: WhatsappMessageUI = {
      _id: new Date().toISOString(),
      receiverId: selectedMemberId ?? '',
      message,
      hostedFile: attachment ? ({} as any) : undefined,
      timestamp: new Date(),
      isLoading: attachment ? true : false,
      isRead: false,
      isReply: false,
    };

    let indexArray = 0;
    setMessages((prev) => {
      const result = [...prev.data, messageObject];
      indexArray = result.length - 1;
      return { isLoading: false, data: result };
    });
    updateRecentMessages(selectedMember, message);
    sendWhatsappRequest(
      recipientId,
      selectedMemberType,
      message,
      attachment,
    ).then((response: { message: string; file?: HostedFile }) => {
      setMessages((prev) => {
        const item = prev.data[indexArray];
        prev.data[indexArray] = {
          ...item,
          hostedFile: response.file ?? undefined,
          isLoading: false,
        };
        return { ...prev };
      });
    });
  };

  const updateRecentMessages = (
    member: Client | Candidate,
    message: string,
  ) => {
    setRecentMessages((prev) => {
      const index = prev.data.findIndex((el) => el.member._id === member._id);
      const list = [...prev.data];

      if (index >= 0) {
        list[index] = {
          ...list[index],
          lastMessage: message ?? '',
          lastMessageTimestamp: new Date().toJSON(),
          unreadMessagesCount:
            selectedMemberId !== member._id
              ? list[index].unreadMessagesCount + 1
              : 0,
        };
      } else {
        list.push({
          member,
          lastMessage: message ?? '',
          lastMessageTimestamp: new Date().toJSON(),
          unreadMessagesCount: selectedMemberId !== member._id ? 1 : 0,
          isReply: true,
        });
      }

      list.sort(
        (a, b) =>
          new Date(b.lastMessageTimestamp).getTime() -
          new Date(a.lastMessageTimestamp).getTime(),
      );

      return { ...prev, data: list };
    });
  };

  //  #################### EMAILS ####################

  const onClickSelectUserConversation = (memberId: string) => {
    navigate(`${PagesUrls.MESSAGES.Index}/${selectedMemberType}/${memberId}`);
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setClients(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const manageData = async () => {
    await AxiosInstance.socialPlatforms
      .socialPlatformsControllerFetchChatList(
        platform,
        process.env.REACT_APP_NAME!,
        selectedMemberType,
        // {
        //   box: '',
        // },
      )
      .then((response) => {
        const result = response.data;
        setRecentMessages({
          isLoading: false,
          data: result.sort(
            (a, b) =>
              new Date(b.lastMessageTimestamp).getTime() -
              new Date(a.lastMessageTimestamp).getTime(),
          ),
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        console.log('error');
        console.log(error);
      });
  };

  useEffect(() => {
    const messageHandler = (messageData: SocketMessageEvent) => {
      const { member, message, memberType, file } = messageData;
      if (
        platform === SocialPlatforms.Whatsapp &&
        selectedMemberType === memberType
      ) {
        updateRecentMessages(member, message ?? '');
      }
      if (selectedMemberId !== member._id) return;

      const messageObject: WhatsappMessageUI = {
        _id: new Date().toISOString(),
        receiverId: selectedMemberId ?? '',
        message: message ?? '',
        hostedFile: file ? ({} as any) : undefined,
        timestamp: new Date(),
        isLoading: false,
        isRead: false,
        isReply: true,
      };
      setMessages((prev) => ({
        isLoading: false,
        data: [...prev.data, messageObject],
      }));

      AxiosInstance.socialPlatforms
        .socialPlatformsControllerMarkChatAsRead(
          platform,
          process.env.REACT_APP_NAME!,
          memberType,
          {
            memberId: selectedMemberId,
          },
        )
        .catch((error: AxiosInstanceErrorResponse) => {
          console.log('error');
          console.log(error);
        });
    };

    socket.on(EWebsocketType.Message, messageHandler);

    return () => {
      socket.off(EWebsocketType.Message, messageHandler);
    };
  }, [socket, selectedMemberId]);

  useEffect(() => {
    manageData();
  }, [selectedMemberType, platform]);

  useEffect(() => {
    if (selectedMemberId) {
      socket?.emit('select', selectedMemberId);
      getLogs(selectedMemberId);
    }
  }, [selectedMemberId]);

  useEffect(() => {
    getCandidates();
    getClients();
  }, []);

  return (
    <MessagesPageContainer data-testid="messages-page-container">
      <div className="messages-header" data-testid="messages-header">
        <div className="left-filter" data-testid="left-filter">
          <div
            data-testid="filter-item-candidates"
            className={`filter-item ${
              selectedMemberType === MEMBER_TYPE.Candidate && 'active'
            }`}
            onClick={() => {
              if (selectedMemberType !== MEMBER_TYPE.Candidate) {
                setRecentMessages({ data: [], isLoading: true });
                navigate(PagesUrls.MESSAGES.Candidate);
              }
            }}
          >
            Candidates
          </div>
          {isCRMApp && (
            <div
              data-testid="filter-item-clients"
              className={`filter-item ${
                selectedMemberType === MEMBER_TYPE.Client && 'active'
              }`}
              onClick={() => {
                if (selectedMemberType !== MEMBER_TYPE.Client) {
                  setRecentMessages({ data: [], isLoading: true });
                  navigate(PagesUrls.MESSAGES.Client);
                }
              }}
            >
              Clients
            </div>
          )}
        </div>
        <div className="right-filter" data-testid="right-filter">
          <SearchInput
            data-testid="search-input"
            placeholder={'Search'}
            onChange={(e) => setSearch(e.target.value ?? '')}
          />
        </div>
      </div>
      <CardContainer
        className="messages-container"
        data-testid="messages-container"
      >
        <MessageList
          data-testid="message-list"
          memberType={selectedMemberType}
          isLoading={recentMessages.isLoading}
          data={filteredRecentMessages}
          selectedMemberId={selectedMemberId}
          onMemberSelected={onClickSelectUserConversation}
        />
        <MessageContent
          data-testid="message-content"
          memberType={selectedMemberType}
          selectedMember={selectedMember}
          listMessages={messages.data}
          isLoading={messages.isLoading}
          sendMessageAndAttachmentWhatsapp={sendMessageAndAttachmentWhatsapp}
        />
      </CardContainer>
    </MessagesPageContainer>
  );
};
