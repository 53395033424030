import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { FieldText } from '../field-text/field-text.component';
import { AutocompleteContainer } from './autocomplete.style';

interface IFilterAutocomplete {
  title: string;
  value: string;
}
interface AutocompleteProps {
  suggestions: IFilterAutocomplete[];
  values: IFilterAutocomplete[];
  onChange: (values: IFilterAutocomplete[]) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  suggestions,
  values,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    IFilterAutocomplete[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onClickSelect = (item: IFilterAutocomplete) => {
    const isValueAlreadyExists = values.some((el) => el.value === item.value);

    if (!isValueAlreadyExists) {
      onChange([...values, item]);
    }
    setInputValue('');
    setShowSuggestions(false);
  };

  const handleRemoveSelectedItem = (value: string) => {
    onChange(values.filter((el) => el.value !== value));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filteredSuggestions.length > 0) {
      onClickSelect(filteredSuggestions[0]);
    }
  };

  useEffect(() => {
    if (inputValue) {
      const filtered = suggestions.filter(
        (el) =>
          el.title.toLowerCase().includes(inputValue.toLowerCase()) &&
          !values.some((existValue) => existValue.value === el.value),
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }, [inputValue, suggestions]);

  return (
    <AutocompleteContainer data-testid="autocomplete-container">
      <FieldText
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type to search..."
        data-testid="autocomplete-input"
      />
      <div
        className="suggestions-container"
        data-testid="suggestions-container"
      >
        {showSuggestions && (
          <ul className="suggestions-list" data-testid="suggestions-list">
            {filteredSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => onClickSelect(suggestion)}
                data-testid={`suggestion-item-${index}`}
              >
                {suggestion.title}
              </li>
            ))}
            {!filteredSuggestions.length && <li>No item available</li>}
          </ul>
        )}
      </div>

      <div className="selected-items" data-testid="selected-items">
        {values.map((item, index) => (
          <span
            key={index}
            className="selected-item"
            data-testid={`selected-item-${index}`}
          >
            {item.title}
            <button
              onClick={() => handleRemoveSelectedItem(item.value)}
              data-testid={`remove-selected-item-${index}`}
            >
              &times;
            </button>
          </span>
        ))}
        {!values.length && <span>No item selected</span>}
      </div>
    </AutocompleteContainer>
  );
};

export default Autocomplete;
